/**
 * Sign up page content
 */

//---------------------------------------
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

import { nameLenLimitation, warnings, actionFeedbacks } from "../../lib/config";

import {
    postRequest,
    isEmptyNullUndefined,
    validateName,
    validatePassword,
    validateInviteCode,
    validateEmail,
    setInputHint,
    allEmpty,
    allNotEmpty,
    delay,
} from "../../lib";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import "./index.css";
//---------------------------------------

function Signup() {
    const [inviteCode, setInviteCode] = useState("");
    const [inviteCodeWarning, setInviteCodeWarning] = useState(warnings.noWarning);

    const [firstName, setFirstName] = useState("");
    const [firstNameWarning, setFirstNameWarning] = useState(warnings.noWarning);

    const [lastName, setLastName] = useState("");
    const [lastNameWarning, setLastNameWarning] = useState(warnings.noWarning);

    const [email, setEmail] = useState("");
    const [emailWarning, setEmailWarning] = useState(warnings.noWarning);

    const [confirmEmail, setConfirmEmail] = useState("");
    const [confirmEmailWarning, setConfirmEmailWarning] = useState(warnings.noWarning);

    const [password, setPassword] = useState("");
    const [passwordWarning, setPasswordWarning] = useState(warnings.noWarning);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(warnings.noWarning);

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isHuman, setIsHuman] = useState(false);

    const [validInputs, setValidInputs] = useState(false);
    const [submitClassName, setSubmitClassName] = useState("btn btn-secondary btn-block");
    const [createButtonText, setCreateButtonText] = useState("Create and Send Confirmation Email");

    const setWarnings = () => {
        // For inviteCode
        if (inviteCode.length > 0 && !validateInviteCode(inviteCode)) {
            setInviteCodeWarning(warnings.inviteCodeInvalidWaring);
        } else {
            setInviteCodeWarning(warnings.noWarning);
        }
        // For firstName
        if (firstName.length > nameLenLimitation) {
            setFirstNameWarning(warnings.nameLenWarning);
        } else if (firstName.length > 0 && !validateName(firstName)) {
            setFirstNameWarning(warnings.nameInvalidWaring);
        } else {
            setFirstNameWarning(warnings.noWarning);
        }
        // For lastName
        if (lastName.length > nameLenLimitation) {
            setLastNameWarning(warnings.nameLenWarning);
        } else if (lastName.length > 0 && !validateName(lastName)) {
            setLastNameWarning(warnings.nameInvalidWaring);
        } else {
            setLastNameWarning(warnings.noWarning);
        }
        // For emails
        if (email.length > 0 && !validateEmail(email)) {
            setEmailWarning(warnings.emailInvalidWaring);
        } else {
            setEmailWarning(warnings.noWarning);
        }
        if (confirmEmail.length > 0 && !validateEmail(confirmEmail)) {
            setConfirmEmailWarning(warnings.emailInvalidWaring);
        } else {
            setConfirmEmailWarning(warnings.noWarning);
        }
        if (email.length > 0 && confirmEmail.length > 0 && email !== confirmEmail) {
            setConfirmEmailWarning(warnings.emailsMatchWaring);
        }
        // For passwords
        if (password.length > 0 && !validatePassword(password)) {
            setPasswordWarning(warnings.passwordInvalidWaring);
        } else {
            setPasswordWarning(warnings.noWarning);
        }
        if (confirmPassword.length > 0 && !validatePassword(confirmPassword)) {
            setConfirmPasswordWarning(warnings.passwordInvalidWaring);
        } else {
            setConfirmPasswordWarning(warnings.noWarning);
        }
        if (password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword) {
            setConfirmPasswordWarning(warnings.newPWNotMatchWaring);
        }
    };

    const setSubmitButton = () => {
        if (
            allNotEmpty([inviteCode, firstName, lastName, email, confirmEmail, password, confirmPassword]) &&
            allEmpty([
                inviteCodeWarning,
                firstNameWarning,
                lastNameWarning,
                emailWarning,
                confirmEmailWarning,
                passwordWarning,
                confirmPasswordWarning,
            ])
        ) {
            setValidInputs(true);
        } else {
            setValidInputs(false);
        }
        if (validInputs && isHuman) {
            setDisableSubmit(false);
            setSubmitClassName("btn btn-success btn-block");
        } else {
            setDisableSubmit(true);
            setSubmitClassName("btn btn-secondary btn-block");
        }
    };

    useEffect(() => {
        // set warnings
        setWarnings();
        // set submit button
        setSubmitButton();
    }, [
        inviteCode,
        inviteCodeWarning,
        firstName,
        firstNameWarning,
        lastName,
        lastNameWarning,
        email,
        emailWarning,
        confirmEmail,
        confirmEmailWarning,
        password,
        confirmPassword,
        passwordWarning,
        confirmPasswordWarning,
        isHuman,
        disableSubmit,
        validInputs,
    ]);

    // Set for Snackbar - start
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(5000);
    const [snackbarAlertText, setSnackbarAlertText] = useState("Invalid email or password");
    const [alertSeverity, setAlertSeverity] = useState("warning");
    const [alertVariant, setAlertVariant] = useState("filled");
    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const showSuccessSnackbar = (successText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(successText);
        setAlertSeverity("success");
    };

    const showWarningSnackbar = (warnText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(warnText);
        setAlertSeverity("warning");
    };
    // Set for Snackbar - end

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const res = await postRequest("/signup", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password, inviteCode, firstName, lastName }),
            });
            if (res.data.code === 200) {
                showSuccessSnackbar(actionFeedbacks.SigupSuccessfully);
                await delay(snackbarAutoHideDuration);
                window.location.href = "/signin";
            } else {
                // If the login is not successful, show an error message
                showWarningSnackbar(actionFeedbacks.signupError);
            }
        } catch (error) {
            showWarningSnackbar(actionFeedbacks.serverError);
        }
    };

    const googleRecaptchaSubmit = async (token) => {
        try {
            if (isEmptyNullUndefined(token)) {
                setIsHuman(false);
            } else {
                const res = await postRequest("/google-recaptcha", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ token }),
                });
                const localIsHuman = res.data.code === 200 ? res.data.data : true;
                if (localIsHuman) {
                    // console.log("Human");
                    setIsHuman(true);
                    setCreateButtonText("Create and Send Confirmation Email");
                } else {
                    // console.log("Not Human");
                    setIsHuman(false);
                    setCreateButtonText("Please Sign up Manually");
                }
            }
        } catch (error) {
            console.log(error);
            setIsHuman(true);
            setCreateButtonText("Create");
        }
    };

    return (
        <>
            <Container
                className="signup-main-container"
                fluid
                style={{
                    flex: 1,
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    width: "95vw",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Row style={{ width: "95vw" }}>
                    <Col
                        md={{ offset: 3, size: 6 }}
                        sm="12"
                        className="text-center"
                        style={{ borderRadius: "5px", background: "#eeeee4", padding: "10px" }}
                    >
                        <div>
                            <h3>Sign Up</h3>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inviteCodeInput"
                                    placeholder="Invite Code"
                                    value={inviteCode}
                                    onChange={(event) => setInviteCode(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(inviteCodeWarning, "Invite Code")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstNameInput"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(firstNameWarning, "First Name")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastNameInput"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(lastNameWarning, "Last Name")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="emailInput"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(emailWarning, "Email")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="confirmEmailInput"
                                    placeholder="Confirm email"
                                    value={confirmEmail}
                                    onChange={(event) => setConfirmEmail(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(confirmEmailWarning, "Confirm Email")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(passwordWarning, "Password")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPasswordInput"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(confirmPasswordWarning, "Confirm Password")}</label>
                            </div>
                            <div className="mb-3" style={{ textAlign: "center" }}>
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    onChange={googleRecaptchaSubmit}
                                />
                            </div>
                            <div className="d-grid col-md-6 offset-md-3 col-sm-12">
                                <button disabled={disableSubmit} type="submit" className={submitClassName} onClick={handleSubmit}>
                                    {createButtonText}
                                </button>
                            </div>
                            <Container fluid>
                                <Row>
                                    <Col md={{ size: 6 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go to <a href="/signin">Sign In</a>
                                        </p>
                                    </Col>
                                    <Col md={{ size: 6 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Forget <a href="/forgetpassword">Password</a>?
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/*---Footer Space---Start*/}
            <Container
                id="footer-space"
                fluid
                style={{
                    padding: "0px",
                    minHeight: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></Container>
            {/*---Footer Space---End*/}
            {/*---Warn Section---Start*/}
            <div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={snackbarAutoHideDuration} // Snackbar will auto close after 5 seconds
                    onClose={snackbarOnClose}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={snackbarOnClose} severity={alertSeverity} variant={alertVariant} sx={{ width: "100%" }}>
                        {snackbarAlertText}
                    </Alert>
                </Snackbar>
            </div>
            {/*---Warn Section---End*/}
        </>
    );
}

export default Signup;
