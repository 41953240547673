import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
    postRequest,
    isEmptyNullUndefined,
    invalidInputValues,
    invalidEmails,
    unmatchValues,
    validatePassword,
    validateEmail,
    setInputHint,
    allEmpty,
    allNotEmpty,
    validateResetCode,
    delay,
} from "../../lib";
import { warnings, actionFeedbacks } from "../../lib/config";

import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./index.css";

function ForgetPassword() {
    const [email, setEmail] = useState("");
    const [emailWarning, setEmailWarning] = useState(warnings.noWarning);

    const [resetCode, setResetCode] = useState("");
    const [resetCodeWarning, setResetCodeWarning] = useState(warnings.noWarning);

    const [password, setPassword] = useState("");
    const [passwordWarning, setPasswordWarning] = useState(warnings.noWarning);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(warnings.noWarning);

    const [disableForgetSubmit, setDisableForgetSubmit] = useState(false);
    const [disableResetSubmit, setDisableResetSubmit] = useState(false);

    const [forgetIsHuman, setForgetIsHuman] = useState(false);
    const [resetIsHuman, setResetIsHuman] = useState(false);
    const [isHuman, setIsHuman] = useState(false);

    const [forgetFormValidInputs, setForgetFormValidInputs] = useState(false);
    const [forgetClassName, setForgetClassName] = useState("btn btn-secondary btn-block");

    const [resetFormValidInputs, setResetFormValidInputs] = useState(false);
    const [resetClassName, setResetClassName] = useState("btn btn-secondary btn-block");

    const [haveResetCode, setHaveResetCode] = useState(false);

    // Set for Snackbar - start
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(5000);
    const [snackbarAlertText, setSnackbarAlertText] = useState("Invalid email or password");
    const [alertSeverity, setAlertSeverity] = useState("warning");
    const [alertVariant, setAlertVariant] = useState("filled");
    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const showSuccessSnackbar = (successText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(successText);
        setAlertSeverity("success");
    };

    const showWarningSnackbar = (warnText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(warnText);
        setAlertSeverity("warning");
    };

    const checkInputsValues = (checkedInputs) => {
        const invalidInputs = invalidInputValues(checkedInputs);
        if (!isEmptyNullUndefined(invalidInputs)) {
            setSnackbarOpen(true);
            setSnackbarAlertText(`The ${invalidInputs.join(", ")} could not be empty.`);
            setAlertSeverity("warning");
            return false;
        }
        return true;
    };

    const checkEmails = () => {
        const invalidInputs = invalidEmails({ email });
        if (!isEmptyNullUndefined(invalidInputs)) {
            setSnackbarOpen(true);
            setSnackbarAlertText(`Invalid ${invalidInputs.join(", ")}.`);
            setAlertSeverity("warning");
            return false;
        }
        return true;
    };

    const checkMatchValues = () => {
        const unmatchInputs = unmatchValues([{ password, "confirm password": confirmPassword }]);
        if (!isEmptyNullUndefined(unmatchInputs)) {
            setSnackbarOpen(true);
            setSnackbarAlertText(`The ${unmatchInputs.join(", ")} are not matched.`);
            setAlertSeverity("warning");
            return false;
        }
        return true;
    };
    // Set for Snackbar - end

    // Set forget password
    const setGetResetCodeButton = () => {
        if (allEmpty([emailWarning]) && allNotEmpty([email])) {
            setForgetFormValidInputs(true);
        } else {
            setForgetFormValidInputs(false);
        }
        if (forgetFormValidInputs && forgetIsHuman) {
            setDisableForgetSubmit(false);
            setForgetClassName("btn btn-success btn-block");
        } else {
            setDisableForgetSubmit(true);
            setForgetClassName("btn btn-secondary btn-block");
        }
    };
    useEffect(() => {
        // set warnings
        if (email.length > 0 && !validateEmail(email)) {
            setEmailWarning(warnings.emailInvalidWaring);
        } else {
            setEmailWarning(warnings.noWarning);
        }
        // set submit button
        setGetResetCodeButton();
    }, [haveResetCode, forgetIsHuman, email, emailWarning, forgetFormValidInputs, disableForgetSubmit, forgetClassName]);

    // Set reset password
    const setWarnings = () => {
        if (email.length > 0 && !validateEmail(email)) {
            setEmailWarning(warnings.emailInvalidWaring);
        } else {
            setEmailWarning(warnings.noWarning);
        }
        // For reset code
        if (resetCode.length > 0 && !validateResetCode(resetCode)) {
            setResetCodeWarning(warnings.resetCodeInvalidWaring);
        } else {
            setResetCodeWarning(warnings.noWarning);
        }
        // For passwords
        if (password.length > 0 && !validatePassword(password)) {
            setPasswordWarning(warnings.passwordInvalidWaring);
        } else {
            setPasswordWarning(warnings.noWarning);
        }
        if (confirmPassword.length > 0 && !validatePassword(confirmPassword)) {
            setConfirmPasswordWarning(warnings.passwordInvalidWaring);
        } else {
            setConfirmPasswordWarning(warnings.noWarning);
        }
        if (password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword) {
            setConfirmPasswordWarning(warnings.newPWNotMatchWaring);
        }
    };

    const setResetPasswordButton = () => {
        if (
            allEmpty([emailWarning, resetCodeWarning, passwordWarning, confirmPasswordWarning]) &&
            allNotEmpty([email, resetCode, password, confirmPassword])
        ) {
            setResetFormValidInputs(true);
        } else {
            setResetFormValidInputs(false);
        }
        if (resetFormValidInputs && resetIsHuman) {
            setDisableResetSubmit(false);
            setResetClassName("btn btn-success btn-block");
        } else {
            setDisableResetSubmit(true);
            setResetClassName("btn btn-secondary btn-block");
        }
    };
    useEffect(() => {
        // set warnings
        setWarnings();
        // set submit button
        setResetPasswordButton();
    }, [
        resetIsHuman,
        haveResetCode,
        email,
        emailWarning,
        resetCode,
        resetCodeWarning,
        password,
        passwordWarning,
        confirmPassword,
        confirmPasswordWarning,
        haveResetCode,
        resetFormValidInputs,
        disableResetSubmit,
        resetClassName,
    ]);

    const getResetCode = async (event) => {
        if (!checkInputsValues({ email })) return;
        if (!checkEmails()) return;
        event.preventDefault();
        try {
            const res = await postRequest("/forget-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });
            if (res.data.code === 200) {
                showSuccessSnackbar(actionFeedbacks.resetCodeSucc);
                setHaveResetCode(true);
            } else {
                showWarningSnackbar(actionFeedbacks.serverError);
            }
        } catch (error) {
            console.log(error);
            showWarningSnackbar(actionFeedbacks.serverError);
        }
    };

    const resetPassword = async (event) => {
        if (!checkInputsValues({ email, "reset code": resetCode, password, "confirm password": confirmPassword })) return;
        if (!checkEmails()) return;
        if (!checkMatchValues()) return;

        event.preventDefault();
        try {
            const res = await postRequest("/reset-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password, resetCode }),
            });
            if (res.data.code === 200) {
                showSuccessSnackbar(actionFeedbacks.changeSaved);
                await delay(snackbarAutoHideDuration);
                window.location.href = "/signin";
            } else if (res.data.code === 400) {
                showWarningSnackbar(res.data.data);
            } else {
                showWarningSnackbar(actionFeedbacks.serverError);
            }
        } catch (error) {
            console.log(error);
            showWarningSnackbar(actionFeedbacks.serverError);
        }
    };

    const googleRecaptchaSubmit = async (event) => {
        try {
            if (isEmptyNullUndefined(event)) {
                if (haveResetCode) {
                    setResetIsHuman(false);
                } else {
                    setForgetIsHuman(false);
                }
            } else {
                const res = await postRequest("/google-recaptcha", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ token: event }),
                });
                const localIsHuman = res.data.code === 200 ? res.data.data : true;
                if (haveResetCode) {
                    setResetIsHuman(localIsHuman);
                } else {
                    setForgetIsHuman(localIsHuman);
                }
            }
        } catch (error) {
            console.log(error);
            if (haveResetCode) {
                setResetIsHuman(true);
            } else {
                setForgetIsHuman(true);
            }
        }
    };

    // const location = useLocation();
    return (
        <>
            <Container
                className="main-container"
                fluid
                style={{
                    flex: "1",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    width: "95vw",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Row style={{ padding: "0px", margin: "0 auto", width: "95vw" }}>
                    <Col
                        md={{ offset: 3, size: 6 }}
                        sm="12"
                        className="text-center"
                        style={{ borderRadius: "5px", background: "#eeeee4", padding: "10px" }}
                    >
                        <div style={{ display: !haveResetCode ? "block" : "none" }}>
                            <h3>Forget Password</h3>

                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="emailInput"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(emailWarning, "Email")}</label>
                            </div>
                            <div className="mb-3" style={{ textAlign: "center" }}>
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    onChange={googleRecaptchaSubmit}
                                />
                            </div>

                            <div className="d-grid col-md-6 offset-md-3 col-sm-12">
                                <button disabled={disableForgetSubmit} type="submit" className={forgetClassName} onClick={getResetCode}>
                                    Get Reset Code
                                </button>
                            </div>
                            <Container fluid>
                                <Row>
                                    <Col md={{ size: 4 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go to{" "}
                                            <a href="#" onClick={() => setHaveResetCode(true)}>
                                                Reset Password
                                            </a>
                                        </p>
                                    </Col>
                                    <Col md={{ size: 4 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go To <a href="/signin">Sign In</a>
                                        </p>
                                    </Col>
                                    <Col md={{ size: 4 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go To <a href="/signup">Sign Up</a>
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div style={{ display: haveResetCode ? "block" : "none" }}>
                            <h3>Reset Password</h3>

                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="resetEmailInput"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(emailWarning, "Email")}</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="resetCode"
                                    placeholder="Reset Code"
                                    value={resetCode}
                                    onChange={(event) => setResetCode(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(resetCodeWarning, "Reset Code")}</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(passwordWarning, "Password")}</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    id="confirmPasswordInput"
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(confirmPasswordWarning, "Confirm password")}</label>
                            </div>

                            <div className="mb-3" style={{ textAlign: "center" }}>
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    onChange={googleRecaptchaSubmit}
                                />
                            </div>

                            <div className="d-grid col-md-6 offset-md-3 col-sm-12">
                                <button disabled={disableResetSubmit} type="submit" className={resetClassName} onClick={resetPassword}>
                                    Reset Password
                                </button>
                            </div>
                            <Container fluid>
                                <Row>
                                    <Col md={{ size: 4 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Forget{" "}
                                            <a href="#" onClick={() => setHaveResetCode(false)}>
                                                Password
                                            </a>
                                            ?
                                        </p>
                                    </Col>
                                    <Col md={{ size: 4 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go to <a href="/signin">Sign In</a>
                                        </p>
                                    </Col>
                                    <Col md={{ size: 4 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go to <a href="/signup">Sign Up</a>
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/*--- Footer Space ---Start*/}
            <Container
                id="footer-space"
                fluid
                style={{
                    padding: "0px",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></Container>
            {/*--- Footer Space ---End*/}
            {/*--- Warn Section ---Start*/}
            <div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={snackbarAutoHideDuration} // Snackbar will auto close after 5 seconds
                    onClose={snackbarOnClose}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={snackbarOnClose} severity={alertSeverity} variant={alertVariant} sx={{ width: "100%" }}>
                        {snackbarAlertText}
                    </Alert>
                </Snackbar>
            </div>
            {/*--- Warn Section ---End*/}
        </>
    );
}

export default ForgetPassword;
