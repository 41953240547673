const passWLenLimit = 6;

const defaultMaxFilesUpload = 2;

module.exports = {
    // ci for localhost:3000 testing;
    // prod for localhost:5000 and online testing
    glashiveVersion: "prod", // ci or prod

    textConsumedCredits: 5,
    imageConsumedCredits: 20,

    passwordLenLimit: passWLenLimit,
    nameLenLimitation: 100,
    resetCodeRegex: /^[0-9]+$/,
    nameRegex: /^[a-zA-Z0-9_.-]+$/,
    emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    passwordRegex: /^(?=.*[!@#$%^&*])(?=.{6,})/, // passWLenLimit = 6
    inviteCodeRegex: /^[a-zA-Z0-9]+$/,

    maxImagesUpload: defaultMaxFilesUpload,
    defaultLanguage: "English",
    defaultLanguageCode: "en",

    imageMaxWidth: 500,
    imageMaxHeight: 500,

    warnings: {
        noWarning: "",
        resetCodeInvalidWaring: "Only supports numbers",
        inviteCodeInvalidWaring: "Only supports chars and numbers",
        emailInvalidWaring: "Invalid email formate",
        nameLenWarning: "Length should > 0 and < 100",
        nameInvalidWaring: "Only supports chars, numbers, - and .",
        passwordInvalidWaring: `at least one special char & ${passWLenLimit}`,
        emailsMatchWaring: "Emails should be the same",
        currtNewPWsSameWaring: "Current and new passwords should be different",
        newPWNotMatchWaring: "Passwords should be the same",

        loginRegisterFirstWarning: "Sorry, you need to login or register first, if you want to use this feature.",

        getFreeCreditsWarning: "Login or register to get free credits to use it.",

        maxImagesWarning: `Max ${defaultMaxFilesUpload} images can be uploaded in a round`,

        noCredits: "No credits, Please contact admin to top up.",
    },
    actionFeedbacks: {
        changeSaved: "Your change has been saved",
        loginSuccessfully: "Signin successfully! Welcome",
        SigupSuccessfully: "Sigup successfully! Before signin, Please validate your email with the link sent to you.",
        invalidOrInactiveEmail: "Please register or active your email",
        loginError: "Invalid email or password",
        signupError: "Invalid email, password or code",
        serverError: "Server is busy, please try it later",

        chatServerError: "Sorry, I am a little bit busy, please try it later",

        emailConfirmed: "Your email has been confirmed, you can login now.",
        invalidActiveLinkError: "Your email active link is invalid or expired.",
        resetCodeSucc: "Please find the reset code in your email, if you signed up with this email",
    },
};
