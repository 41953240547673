import React from "react";
import "./index.css";
import "../../App.css";
import aiFingersImage from "../../images/ai_fingers.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt, faGlobe } from "@fortawesome/free-solid-svg-icons";

function AboutUs(props) {
    return (
        <>
            <div
                fluid
                style={{
                    width: "100vw",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    justifyContent: "space-around",
                    // alignItems: "center",
                }}
            >
                <div
                    className="vertical-center-parent"
                    style={{
                        // backgroundColor: "white",
                        flexGrow: "0",
                        height: "40%",
                        minHeight: "250px",
                        width: "100%",
                        backgroundImage: `url(${aiFingersImage})`,
                        backgroundPosition: "50% 70%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        alignItems: "center",
                        // display: "flex",
                        textAlign: "center",
                    }}
                >
                    <span
                        className="vertical-center-child"
                        style={{ color: "white", width: "100%", fontSize: "1.8em", fontWeight: "bold" }}
                    >
                        Welcome to GlasHive AI
                    </span>
                </div>

                <div
                    className="container"
                    style={{ flexGrow: "1", display: "flex", flexDirection: "column", justifyContent: "space-around" }}
                >
                    <div className="" style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
                        <div
                            className="col-sm-12 col-md-12"
                            style={{ textAlign: "justify", marginTop: "20px", fontSize: "1.2em", color: "#0ED7B3" }}
                        >
                            <p>
                                Welcome to Glashive AI, your premier destination for cutting-edge artificial intelligence solutions. Our
                                company specialises in providing an innovative AI assistant powered by LLM technology.
                            </p>
                            <p>
                                With Glashive AI, you can effortlessly understand objects and translate text within images, enhancing
                                experiences such as exploring attractions and deciphering menus.
                            </p>
                            <p style={{ textAlign: "center", fontSize: "1.5em" }}>Please contact us to get invite code and free credits!</p>
                        </div>
                        <div
                            className="col-sm-12 col-md-12"
                            style={{ textAlign: "justify", marginTop: "20px", fontSize: "1.2em", color: "#0ED7B3" }}
                        >
                            <div className="row">
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <div className="contact-details" style={{ textAlign: "center", margin: "10px", color: "#5491f5" }}>
                                        <div
                                            className="contact-item"
                                            style={{ textAlign: "center", marginBottom: "15px", fontSize: "1.3em" }}
                                        >
                                            <span>Our Details</span>
                                        </div>
                                        <div className="contact-item" style={{ textAlign: "center" }}>
                                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                            <br />
                                            <span> info@glashive.com</span>
                                        </div>
                                        <div className="contact-item" style={{ textAlign: "center" }}>
                                            <FontAwesomeIcon icon={faGlobe} className="icon" />
                                            <br />
                                            <span className="text">www.glashive.xyz</span>
                                        </div>
                                        <div className="contact-item" style={{ textAlign: "center" }}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                                            <br />
                                            <span>
                                                GlasHive Limited, 6 Fern Road, <br />
                                                Sandyford, Dublin, D18 FP98
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flex: "1",
                                    }}
                                >
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2385.8815260769593!2d-6.213684522939964!3d53.27374237226839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486709086c90420b%3A0x536111d6b090a9a4!2s6%20Fern%20Road%2C%20Sandyford%2C%20Dublin%2C%20D18%20FP98!5e0!3m2!1sen!2sie!4v1712439885194!5m2!1sen!2sie"
                                        style={{
                                            border: "0",
                                            width: "100%",
                                            minWidth: "200px",
                                            minHeight: "200px",
                                            flex: "1",
                                        }}
                                        // allowFullScreen={true}
                                        loading="lazy"
                                        // referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*--- Footer Space ---Start*/}
                <div
                    className="footer-space about-footer-space"
                    style={{
                        padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                ></div>
                {/*--- Footer Space ---End*/}
            </div>
        </>
    );
}

export default AboutUs;
