/**
 * Tools and functions to support other files
 * @author Glashive
 * @date 13/03/2024
 * @version 2
 * @references
 * https://platform.openai.com/docs/guides/vision?lang=python
 */

// --------------------------------------------------
const fs = require("fs");
const { isEmptyNullUndefined } = require("../tools");
const { openai } = require("./utils");
const { imageToTextMaxToken, introduceImageQuestion, visionModel } = require("./config");

const { actionFeedbacks } = require("../config");
// --------------------------------------------------

async function imageToBase64Str(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

async function imageToText(base64StrList, imageQuestion = "", maxToken = imageToTextMaxToken) {
    try {
        const question = isEmptyNullUndefined(imageQuestion) ? introduceImageQuestion : imageQuestion;
        if (base64StrList.length <= 0) return {};
        let messages = {
            role: "user",
            content: [{ type: "text", text: question }],
        };
        base64StrList.forEach((base64Str) => {
            messages.content.push({
                type: "image_url",
                image_url: {
                    url: base64Str,
                },
            });
        });

        const response = await openai.chat.completions.create({
            model: visionModel,
            messages: [messages],
            max_tokens: maxToken,
        });
        // console.log("Usage total tokens:", response.usage.total_tokens);
        return response.choices[0];
    } catch (error) {
        console.log(error);
        return actionFeedbacks.chatServerError;
    }
}

// --------------------------------------------------
// -- Test:
// imageToBase64("./test-data/images/1.png");
// --------------------------------------------------

module.exports = {
    imageToBase64Str,
    imageToText,
};
