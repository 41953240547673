// localStorage or sessionStorage
// If want to switch between localStorage and sessionStorage
// Update getLocalItem, setLocalItem and removeLocalItem functions

//-----------------------------------------
// For universal
function getLocalItem(itemName) {
    return localStorage.getItem(itemName);
    // return sessionStorage.getItem(itemName);
}

function setLocalItem(itemName, itemValue) {
    localStorage.setItem(itemName, itemValue);
    // sessionStorage.setItem(itemName, itemValue);
}

function removeLocalItem(itemName) {
    localStorage.removeItem(itemName);
    // sessionStorage.removeItem(itemName);
}

function setLocalItems(itemNames = [], keyValues = {}) {
    itemNames.forEach((itemName) => {
        setLocalItem(itemName, keyValues[itemName]);
    });
}

//  Login state
function getLoginState() {
    // Check if token is expired
    const token = getLocalItem("token") ?? null;
    const tokenExpireDate = getLocalItem("token_expire_date") ?? null;

    if (!token || !tokenExpireDate) return false;
    if (new Date() > new Date(tokenExpireDate)) return false;
    // Check login state
    const loginState = getLocalItem("is_logged_in") ?? "false";
    return loginState === "true";
}

function setLoginState(isLoggedIn) {
    setLocalItem("is_logged_in", isLoggedIn);
}

// First name
function getLocalFirstName() {
    return getLocalItem("first_name") ?? "";
}

function setLocalFirstName(NewFirstName) {
    return setLocalItem("first_name", NewFirstName);
}

// Last name
function getLocalLastName() {
    return getLocalItem("last_name") ?? "";
}

function setLocalLastName(NewLastName) {
    return setLocalItem("last_name", NewLastName);
}

// Credits
function getLocalCredits() {
    return getLocalItem("credits") ?? 0;
}

function setLocalCredits(newCredit) {
    return setLocalItem("credits", newCredit);
}

// Token
function getLocalToken() {
    return getLocalItem("token") ?? "";
}

function setLocalToken(newToken) {
    return setLocalItem("token", newToken);
}

// Email
function getLocalEmail() {
    return getLocalItem("email") ?? "";
}

function setLocalEmail(newEmail) {
    return setLocalItem("email", newEmail);
}

// Messages
function getLocalMessages() {
    const messages = getLocalItem("messages") ?? null;
    if (messages) return JSON.parse(messages);
    return [];
}

function setLocalMessages(newMessages) {
    setLocalItem("messages", JSON.stringify(newMessages));
}

// Remove items
function removeLocalItems(itemNames = []) {
    itemNames.forEach((itemName) => {
        removeLocalItem(itemName);
    });
}

export {
    getLoginState,
    setLoginState,
    getLocalFirstName,
    setLocalFirstName,
    getLocalLastName,
    setLocalLastName,
    setLocalCredits,
    getLocalCredits,
    getLocalToken,
    setLocalToken,
    getLocalMessages,
    setLocalMessages,
    getLocalEmail,
    setLocalEmail,
    removeLocalItems,
    setLocalItem,
    getLocalItem,
    setLocalItems,
};
