import axios from "axios";

const { glashiveVersion } = require("./config");
const { getLocalToken } = require("./session");

let apiRoot = process.env.REACT_APP_API_ROOT;
let addDefaultAPIPath = true;
if (glashiveVersion === "prod") {
    apiRoot = "";
    addDefaultAPIPath = false;
}

const token = getLocalToken();

const defaultConfig = { headers: { Authorization: `Bearer ${token}` } };

//--------------------------------
async function getRequest(path = "/") {
    try {
        const newPath = addDefaultAPIPath ? `${apiRoot}${path}` : path;
        const response = await axios.get(newPath, defaultConfig);
        return response;
    } catch (error) {
        return { code: 400, error };
    }
}

async function getAbsRequest(path = "/") {
    try {
        const response = await axios.get(path, defaultConfig);
        return response;
    } catch (error) {
        return { code: 400, error };
    }
}

async function postRequest(path, param, inputConfig = null) {
    try {
        let finalConfig = inputConfig;
        if (inputConfig) {
            finalConfig.headers.Authorization = defaultConfig.headers.Authorization;
        } else {
            finalConfig = defaultConfig;
        }
        const newPath = addDefaultAPIPath ? `${apiRoot}${path}` : path;
        const response = await axios.post(newPath, param, finalConfig);
        //---------------
        // const response = await axios.post(`${apiRoot}${path}`, param, inputConfig);
        return response;
    } catch (error) {
        return { code: 400, error };
    }
}

async function postAbsRequest(path, param, inputConfig = null) {
    try {
        let finalConfig = inputConfig;
        if (inputConfig) {
            finalConfig.headers.Authorization = defaultConfig.headers.Authorization;
        } else {
            finalConfig = defaultConfig;
        }
        const response = await axios.post(path, param, finalConfig);
        //---------------
        // const response = await axios.post(`${apiRoot}${path}`, param, inputConfig);
        return response;
    } catch (error) {
        return { code: 400, error };
    }
}

async function putRequest(path, param) {
    try {
        const response = await axios.put(`${apiRoot}${path}`, param, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        return { code: 400, error };
    }
}

export { getRequest, postRequest, putRequest, getAbsRequest, postAbsRequest };
