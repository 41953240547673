/**
 * Profile page content
 * Author: Glashive
 * Date: 2024-04/07
 * License: Enterprise License
 */

//---------------------------------------
import "./index.css";
import classnames from "classnames";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState, useEffect } from "react";
import { nameLenLimitation, warnings, actionFeedbacks } from "../../lib/config";
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import {
    postRequest,
    isEmptyNullUndefined,
    validatePassword,
    validateName,
    setInputHint,
    allEmpty,
    allNotEmpty,
    getLocalEmail,
    getLocalCredits,
    getLocalFirstName,
    getLocalLastName,
    handleLocalSaveForSignOut,
    setLocalFirstName,
    setLocalLastName,
} from "../../lib";

//---------------------------------------
function Profile() {
    const emailLS = getLocalEmail();
    const creditsLS = getLocalCredits();
    const firstNameLS = getLocalFirstName();
    const lastNameLS = getLocalLastName();

    const [activeTab, setActiveTab] = useState("1");
    const [isDetailSaveDisabled, setIsDetailSaveDisabled] = useState(true);
    const [isChaPWSaveDisabled, setIsChaPWSaveDisabled] = useState(true);

    const [detailSaveClassName, setDetailSaveClassName] = useState("btn btn-secondary btn-block");
    const [passwordSaveClassName, setPasswordSaveClassName] = useState("btn btn-secondary btn-block");

    const [firstName, setFirstName] = useState(firstNameLS);
    const [firstNameWarning, setFirstNameWarning] = useState(warnings.noWarning);

    const [lastName, setLastName] = useState(lastNameLS);
    const [lastNameWarning, setLastNameWarning] = useState(warnings.noWarning);

    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPWWarning, setCurrentPWWarning] = useState(warnings.noWarning);

    const [newPassword, setNewPassword] = useState("");
    const [newPWWarning, setNewPWWarning] = useState(warnings.noWarning);

    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [confirmNewPWWarning, setConfirmNewPWWarning] = useState(warnings.noWarning);

    // Set for Snackbar - start
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(5000);
    const [snackbarAlertText, setSnackbarAlertText] = useState("Invalid email or password");
    const [alertSeverity, setAlertSeverity] = useState("warning");
    const [alertVariant, setAlertVariant] = useState("filled");
    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const showSuccessSnackbar = (alertText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(alertText);
        setAlertSeverity("success");
    };

    const showWarningSnackbar = (alertText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(alertText);
        setAlertSeverity("warning");
    };
    // Set for Snackbar - end

    const handleSignOut = (event) => {
        event.preventDefault();
        handleLocalSaveForSignOut();
        window.location.href = "/";
    };

    const handleNameUpdate = async (event) => {
        try {
            event.preventDefault();
            const res = await postRequest("/profile", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: emailLS,
                    first_name: firstName,
                    last_name: lastName,
                }),
            });
            if (res.data.code === 200) {
                setLocalFirstName(firstName);
                setLocalLastName(lastName);
                showSuccessSnackbar(actionFeedbacks.changeSaved);
                setIsDetailSaveDisabled(true);
                setDetailSaveClassName("btn btn-secondary btn-block");
            } else if (res.data.code === 400) {
                showWarningSnackbar(actionFeedbacks.loginError);
            } else {
                showWarningSnackbar(actionFeedbacks.serverError);
            }
        } catch (error) {
            showWarningSnackbar(actionFeedbacks.serverError);
        }
    };

    const handlePasswordUpdate = async (event) => {
        try {
            event.preventDefault();
            const res = await postRequest("/profile", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: emailLS,
                    current_password: currentPassword,
                    new_password: newPassword,
                }),
            });
            if (res.data.code === 200) {
                showSuccessSnackbar(actionFeedbacks.changeSaved);
                setIsDetailSaveDisabled(true);
                setDetailSaveClassName("btn btn-secondary btn-block");
                setCurrentPassword("");
                setNewPassword("");
                setConfirmNewPassword("");
            } else if (res.data.code === 400) {
                showWarningSnackbar(actionFeedbacks.loginError);
            } else {
                showWarningSnackbar(actionFeedbacks.serverError);
            }
        } catch (error) {
            showWarningSnackbar(actionFeedbacks.serverError);
        }
    };

    const enableProfileSaveButton = () => {
        if (
            (firstName !== firstNameLS || lastName !== lastNameLS) &&
            isEmptyNullUndefined(firstNameWarning) &&
            isEmptyNullUndefined(lastNameWarning)
        ) {
            setIsDetailSaveDisabled(false);
            setDetailSaveClassName("btn btn-success btn-block");
        } else {
            setIsDetailSaveDisabled(true);
            setDetailSaveClassName("btn btn-secondary btn-block");
        }
    };

    const enablePasswordSaveButton = () => {
        if (
            allEmpty([currentPWWarning, newPWWarning, confirmNewPWWarning]) &&
            allNotEmpty([currentPassword, newPassword, confirmNewPassword])
        ) {
            setIsChaPWSaveDisabled(false);
            setPasswordSaveClassName("btn btn-success btn-block");
        } else {
            setIsChaPWSaveDisabled(true);
            setPasswordSaveClassName("btn btn-secondary btn-block");
        }
    };

    // For the Save profile Button
    useEffect(() => {
        // For firstName update
        if (firstName.length === 0 || firstName.length > nameLenLimitation) {
            setFirstNameWarning(warnings.nameLenWarning);
        } else if (!validateName(firstName)) {
            setFirstNameWarning(warnings.nameInvalidWaring);
        } else {
            setFirstNameWarning(warnings.noWarning);
        }
        // For lastName update
        if (lastName.length === 0 || lastName.length > nameLenLimitation) {
            setLastNameWarning(warnings.nameLenWarning);
        } else if (!validateName(lastName)) {
            setLastNameWarning(warnings.nameInvalidWaring);
        } else {
            setLastNameWarning(warnings.noWarning);
        }
        // For the Save Button
        enableProfileSaveButton();
    }, [activeTab, firstName, lastName, firstNameWarning, lastNameWarning]);

    useEffect(() => {
        // For Current Password
        if (currentPassword.length > 0 && !validatePassword(currentPassword)) {
            setCurrentPWWarning(warnings.passwordInvalidWaring);
        } else {
            setCurrentPWWarning(warnings.noWarning);
        }
        // For new Password
        if (newPassword.length > 0 && !validatePassword(newPassword)) {
            setNewPWWarning(warnings.passwordInvalidWaring);
        } else if (newPassword.length > 0 && currentPassword.length > 0 && newPassword === currentPassword) {
            setNewPWWarning(warnings.currtNewPWsSameWaring);
        } else {
            setNewPWWarning(warnings.noWarning);
        }
        // For new Password
        if (confirmNewPassword.length > 0 && !validatePassword(confirmNewPassword)) {
            setConfirmNewPWWarning(warnings.passwordInvalidWaring);
        } else {
            setConfirmNewPWWarning(warnings.noWarning);
        }

        if (confirmNewPassword.length > 0 && newPassword !== confirmNewPassword) {
            setConfirmNewPWWarning(warnings.newPWNotMatchWaring);
        }

        // For the New Password Save Button
        enablePasswordSaveButton();
    }, [activeTab, currentPassword, newPassword, confirmNewPassword, currentPWWarning, newPWWarning, confirmNewPWWarning]);

    return (
        <>
            <Container
                className="main-container"
                fluid
                style={{ flex: "1", padding: "10px", width: "95vw", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Row style={{ padding: "0px", margin: "0 auto", width: "95vw" }}>
                    <Col
                        md={{ offset: 3, size: 6 }}
                        sm="12"
                        className="text-center"
                        style={{ borderRadius: "5px", background: "#eeeee4", padding: "10px" }}
                    >
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        setActiveTab("1");
                                    }}
                                >
                                    Details
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        setActiveTab("2");
                                    }}
                                >
                                    Change Password
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col md={{ offset: 1, size: 10 }} sm="12" className="text-center">
                                        <div>
                                            <h3>Details</h3>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="emailInput"
                                                    placeholder="Your Email"
                                                    value={emailLS}
                                                    maxLength="100"
                                                    style={{ backgroundColor: "#ccd1d5" }}
                                                    readOnly
                                                ></input>
                                                <label htmlFor="floatingInput">
                                                    Your Email <span style={{ color: "red" }}>(Could not Update)</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="credits"
                                                    placeholder="Your Credit(s)"
                                                    value={creditsLS}
                                                    maxLength="100"
                                                    style={{ backgroundColor: "#ccd1d5" }}
                                                    readOnly
                                                ></input>
                                                <label htmlFor="floatingInput">
                                                    Your Credit(s) <span style={{ color: "red" }}>(Could not Update)</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    id="firstName"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    value={firstName}
                                                    onChange={(event) => setFirstName(event.target.value)}
                                                ></input>
                                                <label htmlFor="floatingInput">{setInputHint(firstNameWarning, "First Name")}</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    id="lastName"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    value={lastName}
                                                    onChange={(event) => setLastName(event.target.value)}
                                                ></input>
                                                <label htmlFor="floatingInput">{setInputHint(lastNameWarning, "Last Name")}</label>
                                            </div>
                                            <Row>
                                                <Col md={6} sm={6} xs={6}>
                                                    <Button
                                                        disabled={isDetailSaveDisabled}
                                                        type="submit"
                                                        className={detailSaveClassName}
                                                        style={{ minWidth: "100px" }}
                                                        onClick={handleNameUpdate}
                                                    >
                                                        Save
                                                    </Button>
                                                </Col>
                                                <Col md={6} sm={6} xs={6}>
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-warning btn-block"
                                                        style={{ minWidth: "100px" }}
                                                        onClick={handleSignOut}
                                                    >
                                                        Sign Out
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col md={{ offset: 1, size: 10 }} sm="12" className="text-center">
                                        <div>
                                            <h3>Change Password</h3>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="password"
                                                    id="currentPasswordInput"
                                                    className="form-control"
                                                    placeholder="Current Password"
                                                    value={currentPassword}
                                                    onChange={(event) => setCurrentPassword(event.target.value)}
                                                ></input>
                                                <label htmlFor="floatingInput">
                                                    {isEmptyNullUndefined(currentPWWarning) ? (
                                                        "Current Password"
                                                    ) : (
                                                        <span style={{ color: "red" }}>{currentPWWarning}</span>
                                                    )}
                                                </label>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input
                                                    type="password"
                                                    id="newPasswordInput"
                                                    className="form-control"
                                                    placeholder="New Password"
                                                    value={newPassword}
                                                    onChange={(event) => setNewPassword(event.target.value)}
                                                ></input>
                                                <label htmlFor="floatingInput">
                                                    {isEmptyNullUndefined(newPWWarning) ? (
                                                        "New Password"
                                                    ) : (
                                                        <span style={{ color: "red" }}>{newPWWarning}</span>
                                                    )}
                                                </label>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input
                                                    id="confirmPasswordInput"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="New Password Again"
                                                    value={confirmNewPassword}
                                                    onChange={(event) => setConfirmNewPassword(event.target.value)}
                                                ></input>
                                                <label htmlFor="floatingInput">
                                                    {isEmptyNullUndefined(confirmNewPWWarning) ? (
                                                        "New Password Again"
                                                    ) : (
                                                        <span style={{ color: "red" }}>{confirmNewPWWarning}</span>
                                                    )}
                                                </label>
                                            </div>

                                            <Row>
                                                <Col md="12" sm="12">
                                                    <Button
                                                        type="submit"
                                                        disabled={isChaPWSaveDisabled}
                                                        className={passwordSaveClassName}
                                                        style={{ minWidth: "100px" }}
                                                        onClick={handlePasswordUpdate}
                                                    >
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Container>
            {/*--- Footer Space ---Start*/}
            <Container
                id="footer-space"
                fluid
                style={{
                    padding: "0px",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></Container>
            {/*--- Footer Space ---End*/}
            {/*--- Snackbar Section ---Start*/}
            <div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={snackbarAutoHideDuration} // Snackbar will auto close after 5 seconds
                    onClose={snackbarOnClose}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={snackbarOnClose} severity={alertSeverity} variant={alertVariant} sx={{ width: "100%" }}>
                        {snackbarAlertText}
                    </Alert>
                </Snackbar>
            </div>
            {/*--- Snackbar Section ---End*/}
        </>
    );
}

export default Profile;
