/**
 * This is the main file of this APP
 */
import { createBrowserHistory } from "history";
import { Navigation, Footer } from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Pages
import { Home, AboutUs, Signin, Signup, Profile, EmailConfirm, ForgetPassword, AIAssistant } from "./pages";

const history = createBrowserHistory();
function App() {
    return (
        <Router history={history}>
            <Navigation />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/ai-assistant" exact element={<AIAssistant />} />
                <Route path="/signin" exact element={<Signin />} />
                <Route path="/signup" exact element={<Signup />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route path="/aboutus" exact element={<AboutUs />} />
                <Route path="/emailconfirm" exact element={<EmailConfirm />} />
                <Route path="/forgetpassword" exact element={<ForgetPassword />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
