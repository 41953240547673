// https://www.npmjs.com/package/@ladjs/country-language

import { jsonKeyExists } from "../tools";

const CountryLanguage = require("@ladjs/country-language");

const { getCountryLanagesDetails } = require("./countrys-lanages");

const countryLangDetails = getCountryLanagesDetails();

function getLangByCountryCode(countryCode = "GB", defaultLang = "English") {
    let language = defaultLang;
    // if (CountryLanguage.countryCodeExists(countryCode)) {
    //     const country = CountryLanguage.getCountry(countryCode);
    //     if (jsonKeyExists(country, "languages")) {
    //         return country.languages[0].name[0];
    //     }
    // }

    if (countryLangDetails[countryCode]) return countryLangDetails[countryCode].english_language;
    return language;
}

export { getLangByCountryCode };
export { getCountryCodeDetails, getCountryCodes, getCountryLanguageCustomLabels, getLanguageCode } from "./config";
