import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { postRequest, delay } from "../../lib";
import { actionFeedbacks } from "../../lib/config";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./index.css";

function EmailConfirm() {
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [confirmedResult, setConfirmedResult] = useState("Failed");

    // Set for Snackbar - start
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(5000);
    const [snackbarAlertText, setSnackbarAlertText] = useState("Invalid email or password");
    const [alertSeverity, setAlertSeverity] = useState("warning");
    const [alertVariant, setAlertVariant] = useState("filled");
    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const showSuccessSnackbar = (successText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(successText);
        setAlertSeverity("success");
    };

    const showWarningSnackbar = (warnText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(warnText);
        setAlertSeverity("warning");
    };
    // Set for Snackbar - end

    const emailconfirmWithToken = async () => {
        try {
            console.log("in emailconfirmWithToken");
            const params = new URLSearchParams(window.location.search);
            const token = params.get("token") ?? "";

            const res = await postRequest(`/email-confirm`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token,
                }),
            });

            if (res.data.code === 200) {
                setConfirmedResult("OK");
                showSuccessSnackbar(actionFeedbacks.emailConfirmed);
                await delay(snackbarAutoHideDuration);
                window.location.href = "/signin";
            } else {
                setConfirmedResult("Failed");
                showWarningSnackbar(actionFeedbacks.invalidActiveLinkError);
                await delay(snackbarAutoHideDuration);
                window.location.href = "/";
            }
        } catch (error) {
            console.log(error);
            setConfirmedResult("Failed");
            showWarningSnackbar(actionFeedbacks.serverError);
            await delay(snackbarAutoHideDuration);
            window.location.href = "/";
        }
    };

    useEffect(() => {
        emailconfirmWithToken();
    }, []);

    return (
        <>
            <Container
                className="main-container"
                fluid
                style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    width: "95vw",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Row md={{ offset: 3, size: 6 }} sm="12" className="text-center" style={{ padding: "10px" }}>
                    <Col name="detailTitle" xs={12} className="text-center" style={{ fontSize: "2em", padding: "1em" }}>
                        Confirm email
                    </Col>
                    <Col name="about" xs={{ size: 12 }} sm={{ size: 8, order: 2, offset: 2 }} style={{ fontSize: "1.2em", padding: "1em" }}>
                        {confirmedResult}
                    </Col>
                </Row>
            </Container>

            {/*--- Footer Space ---Start*/}
            <Container
                id="footer-space"
                fluid
                style={{
                    padding: "0px",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></Container>
            {/*--- Footer Space ---End*/}
            {/*--- Warn Section ---Start*/}
            <div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={snackbarAutoHideDuration} // Snackbar will auto close after 5 seconds
                    onClose={snackbarOnClose}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={snackbarOnClose} severity={alertSeverity} variant={alertVariant} sx={{ width: "100%" }}>
                        {snackbarAlertText}
                    </Alert>
                </Snackbar>
            </div>
            {/*--- Warn Section ---End*/}
        </>
    );
}

export default EmailConfirm;
