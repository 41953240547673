/**
 * Sign in page content
 */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
    postRequest,
    isEmptyNullUndefined,
    validatePassword,
    validateEmail,
    allEmpty,
    allNotEmpty,
    setInputHint,
    delay,
    setLoginState,
    setLocalItems,
} from "../../lib";

import { warnings, actionFeedbacks, glashiveVersion } from "../../lib/config";

import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./index.css";

const isTesting = glashiveVersion === "ci" ? true : false;

function Signin() {
    const [email, setEmail] = useState("");
    const [emailWarning, setEmailWarning] = useState(warnings.noWarning);

    const [password, setPassword] = useState("");
    const [passwordWarning, setPasswordWarning] = useState(warnings.noWarning);

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isHuman, setIsHuman] = useState(false);

    const [validInputs, setValidInputs] = useState(false);
    const [submitClassName, setSubmitClassName] = useState("btn btn-secondary btn-block");

    // Set for Snackbar - start
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(3000);
    const [snackbarAlertText, setSnackbarAlertText] = useState("Invalid email or password");
    const [alertSeverity, setAlertSeverity] = useState("warning");
    const [alertVariant, setAlertVariant] = useState("filled");
    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const showSuccessSnackbar = (successText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(successText);
        setAlertSeverity("success");
    };

    const showWarningSnackbar = (warnText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(warnText);
        setAlertSeverity("warning");
    };
    // Set for Snackbar - end

    useEffect(() => {
        // set warnings
        setWarnings();
        // set submit button
        setSubmitButton();
        // console.log(isHuman, validInputs, disableSubmit);
    }, [email, emailWarning, password, passwordWarning, isHuman, disableSubmit, validInputs]);
    const setWarnings = () => {
        if (email.length > 0 && !validateEmail(email)) {
            setEmailWarning(warnings.emailInvalidWaring);
        } else {
            setEmailWarning(warnings.noWarning);
        }
        if (password.length > 0 && !validatePassword(password)) {
            setPasswordWarning(warnings.passwordInvalidWaring);
        } else {
            setPasswordWarning(warnings.noWarning);
        }
    };
    const setSubmitButton = () => {
        if (allEmpty([emailWarning, passwordWarning]) && allNotEmpty([email, password])) {
            setValidInputs(true);
        } else {
            setValidInputs(false);
        }
        if (validInputs && isHuman) {
            setDisableSubmit(false);
            setSubmitClassName("btn btn-success btn-block");
        } else {
            setDisableSubmit(true);
            setSubmitClassName("btn btn-secondary btn-block");
        }
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const res = await postRequest("/signin", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });

            if (res.data.code === 200) {
                const data = res.data.data;
                setEmail(data.email);
                const localItems = ["is_logged_in", "id", "email", "first_name", "last_name", "credits", "token", "token_expire_date"];
                setLocalItems(localItems, { is_logged_in: true, ...data });
                showSuccessSnackbar(`${actionFeedbacks.loginSuccessfully} ${data.first_name}`);
                await delay(snackbarAutoHideDuration);
                window.location.href = "/";
            } else if (res.data.code === 204) {
                setLoginState(false);
                showWarningSnackbar(actionFeedbacks.invalidOrInactiveEmail);
            } else if (res.data.code === 400) {
                setLoginState(false);
                setPassword("");
                showWarningSnackbar(res.data.data);
            } else if (res.data.code === 500) {
                // Server error
                setLoginState(false);
                showWarningSnackbar(res.data.data);
            }
        } catch (error) {
            console.log(error);
            showWarningSnackbar(actionFeedbacks.serverError);
        }
    };

    const googleRecaptchaSubmit = async (event) => {
        try {
            if (isTesting) {
                setIsHuman(true);
                return;
            }
            if (isEmptyNullUndefined(event)) {
                setIsHuman(false);
            } else {
                const res = await postRequest("/google-recaptcha", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ token: event }),
                });
                const localIsHuman = res.data.code === 200 ? res.data.data : true;
                setIsHuman(localIsHuman);
            }
        } catch (error) {
            console.log(error);
            setIsHuman(true);
        }
    };

    return (
        <>
            <Container
                className="main-container"
                fluid
                style={{
                    flex: "1",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    width: "95vw",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Row style={{ padding: "0px", margin: "0 auto", width: "95vw" }}>
                    {/* #9de3d6 #eeeee4*/}
                    <Col
                        md={{ offset: 3, size: 6 }}
                        sm="12"
                        className="text-center"
                        style={{ borderRadius: "5px", background: "#eeeee4", padding: "10px" }}
                    >
                        <div>
                            <h3>Sign In</h3>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="emailInput"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(emailWarning, "Email")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    maxLength="100"
                                ></input>
                                <label htmlFor="floatingInput">{setInputHint(passwordWarning, "Password")}</label>
                            </div>

                            <div className="mb-3" style={{ textAlign: "center" }}>
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    onChange={googleRecaptchaSubmit}
                                />
                            </div>
                            {/* <div className="mb-3">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label" htmlFor="customCheck1">
                                        Remember me
                                    </label>
                                </div>
                            </div> */}
                            <div className="mb-3 d-grid col-md-6 offset-md-3 col-sm-12">
                                <button disabled={disableSubmit} type="submit" className={submitClassName} onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                            <Container fluid>
                                <Row>
                                    <Col md={{ size: 6 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Go To <a href="/signup">Sign Up</a>
                                        </p>
                                    </Col>
                                    <Col md={{ size: 6 }} sm="12" className="text-center" style={{ background: "#eeeee4" }}>
                                        <p className="forgot-password text-right" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            Forget <a href="/forgetpassword">Password</a>?
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/*--- Footer Space ---Start*/}
            <Container
                id="footer-space"
                fluid
                style={{
                    padding: "0px",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></Container>
            {/*--- Footer Space ---End*/}
            {/*--- Snackbar Section ---Start*/}
            <div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={snackbarAutoHideDuration} // Snackbar will auto close after 5 seconds
                    onClose={snackbarOnClose}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={snackbarOnClose} severity={alertSeverity} variant={alertVariant} sx={{ width: "100%" }}>
                        {snackbarAlertText}
                    </Alert>
                </Snackbar>
            </div>
            {/*--- Snackbar Section ---End*/}
        </>
    );
}

export default Signin;
