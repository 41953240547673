import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default class WarnSnackbar extends React.Component {
    constructor(props) {
        super(props);
        // this.onClose = this.onClose.bind(this);
        this.state = {
            open: this.props.open,
            autoHideDuration: this.props.autoHideDuration || 5000, // Snackbar will auto close after 5 seconds
            alertText: this.props.alertText,
            severity: this.props.severity || "warning", // 'success' | 'info' | 'warning' | 'error';
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.setState({ open: this.props.open });
        }
        if (this.props.dropDownValue !== prevProps.dropDownValue) {
            this.setState({ dropDownValue: this.props.dropDownValue });
        }
    }

    componentDidMount() {
        // console.log("this.state.open: ", this.state.open);
        // console.log("this.state.autoHideDuration: ", this.state.autoHideDuration);
    }

    handleClose = () => {
        this.setState({ open: false }, () => {
            // Callback function to update noLoginWarn to false
            this.props.setNoLoginWarn(false);
        });
        this.props.onClose();
        this.props.updateNoLoginWarn(false);
    };

    render() {
        const { open } = this.props;
        return (
            <Snackbar
                open={open}
                autoHideDuration={this.state.dropDownValue}
                onClose={this.handleClose}
                // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Alert onClose={this.onClose} severity={this.state.severity} variant="filled" sx={{ width: "100%" }}>
                    {this.state.alertText}
                </Alert>
            </Snackbar>
        );
    }
}
