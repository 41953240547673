// https://www.npmjs.com/package/react-flags-select
// https://github.com/ekwonye-richard/react-flags-select/blob/master/src/data/countries.ts
const { jsonKeyExists } = require("../tools");
const { getCountryLanagesDetails } = require("./countrys-lanages");

const countryCodeDetails = getCountryLanagesDetails();

function getCountryCodeDetails() {
    return countryCodeDetails;
}

function getEnglishLanguage(countryCode) {
    if (jsonKeyExists(countryCodeDetails, countryCode)) return countryCode[countryCode].english_language;
    return countryCode.GB.english_language;
}

function getCountryCodes() {
    const countryCodes = Object.keys(countryCodeDetails);
    return countryCodes;
}

function getLanguageCode(countryCode) {
    const upperCountryCode = countryCode.toUpperCase();
    if (jsonKeyExists(countryCodeDetails, upperCountryCode)) return countryCodeDetails[upperCountryCode].language_code;
    return countryCode.GB.language_code;
}

function getCountryLanguageCustomLabels() {
    let customLabels = {};
    const countryCodes = getCountryCodes();
    countryCodes.forEach((countryCode) => {
        const combined = `${countryCodeDetails[countryCode]["english"]}-${countryCodeDetails[countryCode]["native_language"]}`;
        customLabels[countryCode] = combined;
    });
    return customLabels;
}

export { getCountryCodeDetails, getCountryCodes, getCountryLanguageCustomLabels, getEnglishLanguage, getLanguageCode };
