const fullCountryCodeDetails = {
    GB: {
        english: "United Kingdom",
        native: "United Kingdom",
        english_language: "English",
        native_language: "English",
        language_code: "en",
    },
    CN: { english: "China", native: "中国", english_language: "Chinese", native_language: "中文", language_code: "zh" },
    IE: { english: "Ireland", native: "Éire", english_language: "Irish", native_language: "Gaeilge", language_code: "ga" },
    //     AF: { english: "Afghanistan", native: "افغانستان", english_language: "Dari", native_language: "دری" },
    //     AL: { english: "Albania", native: "Shqipëria", english_language: "Albanian", native_language: "Shqip" },
    //     DZ: { english: "Algeria", native: "الجزائر", english_language: "Arabic", native_language: "العربية" },
    //     AS: { english: "American Samoa", native: "Amerika Sāmoa", english_language: "Samoan", native_language: "Gagana fa'a Sāmoa" },
    //     AD: { english: "Andorra", native: "Andorra", english_language: "Catalan", native_language: "Català" },
    //     AO: { english: "Angola", native: "Angola", english_language: "Portuguese", native_language: "Português" },
    //     AI: { english: "Anguilla", native: "Anguilla", english_language: "English", native_language: "English" },
    //     AG: { english: "Antigua and Barbuda", native: "Antigua and Barbuda", english_language: "English", native_language: "English" },
    //     AR: { english: "Argentina", native: "Argentina", english_language: "Spanish", native_language: "Español" },
    //     AM: { english: "Armenia", native: "Հայաստան", english_language: "Armenian", native_language: "Հայերեն" },
    //     AW: { english: "Aruba", native: "Aruba", english_language: "Dutch", native_language: "Nederlands" },
    //     AU: { english: "Australia", native: "Australia", english_language: "English", native_language: "English" },
    AT: { english: "Austria", native: "Österreich", english_language: "German", native_language: "Deutsch", language_code: "de" },
    //     AZ: { english: "Azerbaijan", native: "Azərbaycan", english_language: "Azerbaijani", native_language: "Azərbaycan dili" },
    //     BS: { english: "Bahamas", native: "Bahamas", english_language: "English", native_language: "English" },
    //     BH: { english: "Bahrain", native: "البحرين", english_language: "Arabic", native_language: "العربية" },
    //     BD: { english: "Bangladesh", native: "বাংলাদেশ", english_language: "Bengali", native_language: "বাংলা" },
    //     BB: { english: "Barbados", native: "Barbados", english_language: "English", native_language: "English" },
    //     BY: { english: "Belarus", native: "Беларусь", english_language: "Belarusian", native_language: "Беларуская" },
    BE: { english: "Belgium", native: "België", english_language: "French", native_language: "Français", language_code: "fr" },
    // BE: { english: "Belgium", native: "België", english_language: "German", native_language: "Deutsch", language_code: "de" },
    //     BZ: { english: "Belize", native: "Belize", english_language: "English", native_language: "English" },
    //     BJ: { english: "Benin", native: "Bénin", english_language: "French", native_language: "Français" },
    //     BM: { english: "Bermuda", native: "Bermuda", english_language: "English", native_language: "English" },
    //     BT: { english: "Bhutan", native: "འབྲུག", english_language: "Dzongkha", native_language: "རྫོང་ཁ" },
    //     BO: { english: "Bolivia, Plurinational State of", native: "Bolivia", english_language: "Spanish", native_language: "Español" },
    //     BA: { english: "Bosnia and Herzegovina", native: "Bosna i Hercegovina", english_language: "Bosnian", native_language: "Bosanski" },
    //     BW: { english: "Botswana", native: "Botswana", english_language: "English", native_language: "English" },
    //     BR: { english: "Brazil", native: "Brasil", english_language: "Portuguese", native_language: "Português" },
    //     IO: {
    //         english: "British Indian Ocean Territory",
    //         native: "British Indian Ocean Territory",
    //         english_language: "English",
    //         native_language: "English",
    //     },
    BG: { english: "Bulgaria", native: "България", english_language: "Bulgarian", native_language: "Български", language_code: "bg" },
    //     BF: { english: "Burkina Faso", native: "Burkina Faso", english_language: "French", native_language: "Français" },
    //     BI: { english: "Burundi", native: "Burundi", english_language: "French", native_language: "Français" },
    //     KH: { english: "Cambodia", native: "កម្ពុជា", english_language: "Khmer", native_language: "ភាសាខ្មែរ" },
    //     CM: { english: "Cameroon", native: "Cameroun", english_language: "French, English", native_language: "Français, English" },
    //     CA: { english: "Canada", native: "Canada", english_language: "English, French", native_language: "English, Français" },
    //     CV: { english: "Cape Verde", native: "Cabo Verde", english_language: "Portuguese", native_language: "Português" },
    //     KY: { english: "Cayman Islands", native: "Cayman Islands", english_language: "English", native_language: "English" },
    //     CF: {
    //         english: "Central African Republic",
    //         native: "République centrafricaine",
    //         english_language: "French",
    //         native_language: "Français",
    //     },
    //     TD: { english: "Chad", native: "Tchad", english_language: "French, Arabic", native_language: "Français, العربية" },
    //     CL: { english: "Chile", native: "Chile", english_language: "Spanish", native_language: "Español" },
    // CN: { english: "China", native: "中国", english_language: "Chinese", native_language: "中文", language_code: "zh" },
    //     CO: { english: "Colombia", native: "Colombia", english_language: "Spanish", native_language: "Español" },
    //     KM: {
    //         english: "Comoros",
    //         native: "Comores",
    //         english_language: "Comorian, French, Arabic",
    //         native_language: "Shikomori, Français, العربية",
    //     },
    //     CG: { english: "Congo", native: "Congo", english_language: "French", native_language: "Français" },
    //     CD: {
    //         english: "Democratic Republic of the Congo",
    //         native: "République démocratique du Congo",
    //         english_language: "French",
    //         native_language: "Français",
    //     },
    //     CK: { english: "Cook Islands", native: "Cook Islands", english_language: "English", native_language: "English" },
    //     CR: { english: "Costa Rica", native: "Costa Rica", english_language: "Spanish", native_language: "Español" },
    //     CI: { english: "Côte d'Ivoire", native: "Côte d'Ivoire", english_language: "French", native_language: "Français" },
    HR: { english: "Croatia", native: "Hrvatska", english_language: "Croatian", native_language: "Hrvatski", language_code: "hr" },
    //     CU: { english: "Cuba", native: "Cuba", english_language: "Spanish", native_language: "Español" },
    //     CW: { english: "Curaçao", native: "Curaçao", english_language: "Dutch", native_language: "Nederlands" },
    CY: { english: "Cyprus", native: "Κύπρος", english_language: "Greek", native_language: "Ελληνικά", language_code: "el" },
    CZ: {
        english: "Czech Republic",
        native: "Česká republika",
        english_language: "Czech",
        native_language: "Čeština",
        language_code: "cs",
    },
    DK: { english: "Denmark", native: "Danmark", english_language: "Danish", native_language: "Dansk", language_code: "da" },
    //     DJ: { english: "Djibouti", native: "Djibouti", english_language: "French, Arabic", native_language: "Français, العربية" },
    //     DM: { english: "Dominica", native: "Dominica", english_language: "English", native_language: "English" },
    //     DO: { english: "Dominican Republic", native: "República Dominicana", english_language: "Spanish", native_language: "Español" },
    //     EC: { english: "Ecuador", native: "Ecuador", english_language: "Spanish", native_language: "Español" },
    //     EG: { english: "Egypt", native: "مصر", english_language: "Arabic", native_language: "العربية" },
    //     SV: { english: "El Salvador", native: "El Salvador", english_language: "Spanish", native_language: "Español" },
    //     GQ: { english: "Equatorial Guinea", native: "Guinea Ecuatorial", english_language: "Spanish", native_language: "Español" },
    //     ER: { english: "Eritrea", native: "ኤርትራ", english_language: "Tigrinya", native_language: "ትግርኛ" },
    EE: { english: "Estonia", native: "Eesti", english_language: "Estonian", native_language: "Eesti", language_code: "et" },
    //     ET: { english: "Ethiopia", native: "ኢትዮጵያ", english_language: "Amharic", native_language: "አማርኛ" },
    //     FK: { english: "Falkland Islands (Malvinas)", native: "Falkland Islands", english_language: "English", native_language: "English" },
    //     FO: { english: "Faroe Islands", native: "Føroyar", english_language: "Faroese", native_language: "Føroyskt" },
    //     FJ: { english: "Fiji", native: "Fiji", english_language: "English, Fijian", native_language: "English, Vosa Vakaviti" },
    FI: { english: "Finland", native: "Suomi", english_language: "Finnish", native_language: "Suomi", language_code: "fi" },
    FR: { english: "France", native: "France", english_language: "French", native_language: "Français", language_code: "fr" },
    //     PF: { english: "French Polynesia", native: "Polynésie française", english_language: "French", native_language: "Français" },
    //     GA: { english: "Gabon", native: "Gabon", english_language: "French", native_language: "Français" },
    //     GM: { english: "Gambia", native: "Gambia", english_language: "English", native_language: "English" },
    //     GE: { english: "Georgia", native: "საქართველო", english_language: "Georgian", native_language: "ქართული" },
    DE: { english: "Germany", native: "Deutschland", english_language: "German", native_language: "Deutsch", language_code: "de" },
    //     GH: { english: "Ghana", native: "Ghana", english_language: "English", native_language: "English" },
    //     GI: { english: "Gibraltar", native: "Gibraltar", english_language: "English", native_language: "English" },
    GR: { english: "Greece", native: "Ελλάδα", english_language: "Greek", native_language: "Ελληνικά", language_code: "el" },
    //     GL: { english: "Greenland", native: "Kalaallit Nunaat", english_language: "Greenlandic", native_language: "Kalaallisut" },
    //     GD: { english: "Grenada", native: "Grenada", english_language: "English", native_language: "English" },
    //     GU: { english: "Guam", native: "Guåhån", english_language: "English", native_language: "English" },
    //     GT: { english: "Guatemala", native: "Guatemala", english_language: "Spanish", native_language: "Español" },
    //     GG: { english: "Guernsey", native: "Guernsey", english_language: "English", native_language: "English" },
    //     GN: { english: "Guinea", native: "Guinée", english_language: "French", native_language: "Français" },
    //     GW: { english: "Guinea-Bissau", native: "Guiné-Bissau", english_language: "Portuguese", native_language: "Português" },
    //     HT: { english: "Haiti", native: "Haïti", english_language: "French", native_language: "Français" },
    //     HN: { english: "Honduras", native: "Honduras", english_language: "Spanish", native_language: "Español" },
    //     HK: { english: "Hong Kong", native: "香港", english_language: "Chinese, English", native_language: "中文, English" },
    HU: { english: "Hungary", native: "Magyarország", english_language: "Hungarian", native_language: "Magyar", language_code: "hu" },
    //     IS: { english: "Iceland", native: "Ísland", english_language: "Icelandic", native_language: "Íslenska" },
    //     IN: { english: "India", native: "भारत", english_language: "Hindi, English", native_language: "हिन्दी, English" },
    //     ID: { english: "Indonesia", native: "Indonesia", english_language: "Indonesian", native_language: "Bahasa Indonesia" },
    //     IR: { english: "Iran, Islamic Republic of", native: "ایران", english_language: "Persian", native_language: "فارسی" },
    //     IQ: { english: "Iraq", native: "العراق", english_language: "Arabic, Kurdish", native_language: "العربية, Kurdî" },
    // IE: { english: "Ireland", native: "Éire", english_language: "Irish", native_language: "Gaeilge", language_code: "ga" },
    // IE: { english: "Ireland", native: "Éire", english_language: "English", native_language: "English", language_code: "en" },
    //     IM: { english: "Isle of Man", native: "Isle of Man", english_language: "English", native_language: "English" },
    //     IL: { english: "Israel", native: "ישראל", english_language: "Hebrew", native_language: "עברית" },
    IT: { english: "Italy", native: "Italia", english_language: "Italian", native_language: "Italiano", language_code: "it" },
    //     JM: { english: "Jamaica", native: "Jamaica", english_language: "English", native_language: "English" },
    //     JP: { english: "Japan", native: "日本", english_language: "Japanese", native_language: "日本語" },
    //     JE: { english: "Jersey", native: "Jersey", english_language: "English", native_language: "English" },
    //     JO: { english: "Jordan", native: "الأردن", english_language: "Arabic", native_language: "العربية" },
    //     KZ: { english: "Kazakhstan", native: "Қазақстан", english_language: "Kazakh, Russian", native_language: "Қазақ тілі, Русский" },
    //     KE: { english: "Kenya", native: "Kenya", english_language: "English", native_language: "English" },
    //     KI: { english: "Kiribati", native: "Kiribati", english_language: "English", native_language: "English" },
    //     KP: { english: "North Korea", native: "조선민주주의인민공화국", english_language: "Korean", native_language: "한국어" },
    //     KR: { english: "South Korea", native: "대한민국", english_language: "Korean", native_language: "한국어" },
    //     KW: { english: "Kuwait", native: "الكويت", english_language: "Arabic", native_language: "العربية" },
    //     KG: { english: "Kyrgyzstan", native: "Кыргызстан", english_language: "Kyrgyz", native_language: "Кыргызча" },
    //     LA: { english: "Lao People's Democratic Republic", native: "ລາວ", english_language: "Lao", native_language: "ພາສາລາວ" },
    LV: { english: "Latvia", native: "Latvija", english_language: "Latvian", native_language: "Latviešu", language_code: "lv" },
    //     LB: { english: "Lebanon", native: "لبنان", english_language: "Arabic", native_language: "العربية" },
    //     LS: { english: "Lesotho", native: "Lesotho", english_language: "English", native_language: "English" },
    //     LR: { english: "Liberia", native: "Liberia", english_language: "English", native_language: "English" },
    //     LY: { english: "Libya", native: "ليبيا", english_language: "Arabic", native_language: "العربية" },
    //     LI: { english: "Liechtenstein", native: "Liechtenstein", english_language: "German", native_language: "Deutsch" },
    LT: { english: "Lithuania", native: "Lietuva", english_language: "Lithuanian", native_language: "Lietuvių", language_code: "lt" },
    LU: {
        english: "Luxembourg",
        native: "Luxembourg",
        english_language: "Luxembourgish",
        native_language: "Lëtzebuergesch",
        language_code: "lb",
    },
    //     MO: { english: "Macao", native: "澳門", english_language: "Chinese", native_language: "中文" },
    //     MK: { english: "Republic of Macedonia", native: "Македонија", english_language: "Macedonian", native_language: "Македонски" },
    //     MG: { english: "Madagascar", native: "Madagasikara", english_language: "Malagasy", native_language: "Malagasy" },
    //     MW: { english: "Malawi", native: "Malawi", english_language: "English", native_language: "English" },
    //     MY: { english: "Malaysia", native: "Malaysia", english_language: "Malay", native_language: "Bahasa Melayu" },
    //     MV: { english: "Maldives", native: "ދިވެހި", english_language: "Dhivehi", native_language: "ދިވެހިބަސް" },
    //     ML: { english: "Mali", native: "Mali", english_language: "French", native_language: "Français" },
    MT: { english: "Malta", native: "Malta", english_language: "Maltese", native_language: "Malti", language_code: "mt" },
    //     MH: {
    //         english: "Marshall Islands",
    //         native: "Aelōn̄ in M̧ajeļ",
    //         english_language: "Marshallese, English",
    //         native_language: "Kajin M̧ajeļ, English",
    //     },
    //     MQ: { english: "Martinique", native: "Martinique", english_language: "French", native_language: "Français" },
    //     MR: { english: "Mauritania", native: "موريتانيا", english_language: "Arabic", native_language: "العربية" },
    //     MU: { english: "Mauritius", native: "Mauritius", english_language: "English", native_language: "English" },
    //     MX: { english: "Mexico", native: "México", english_language: "Spanish", native_language: "Español" },
    //     FM: { english: "Micronesia, Federated States of", native: "Micronesia", english_language: "English", native_language: "English" },
    //     MD: { english: "Republic of Moldova", native: "Republica Moldova", english_language: "Romanian", native_language: "Română" },
    //     MC: { english: "Monaco", native: "Monaco", english_language: "French", native_language: "Français" },
    //     MN: { english: "Mongolia", native: "Монгол улс", english_language: "Mongolian", native_language: "Монгол" },
    //     ME: { english: "Montenegro", native: "Crna Gora", english_language: "Montenegrin", native_language: "Crnogorski" },
    //     MS: { english: "Montserrat", native: "Montserrat", english_language: "English", native_language: "English" },
    //     MA: { english: "Morocco", native: "المغرب", english_language: "Arabic", native_language: "العربية" },
    //     MZ: { english: "Mozambique", native: "Moçambique", english_language: "Portuguese", native_language: "Português" },
    //     MM: { english: "Myanmar", native: "မြန်မာ", english_language: "Burmese", native_language: "မြန်မာစာ" },
    //     NA: { english: "Namibia", native: "Namibia", english_language: "English", native_language: "English" },
    //     NR: { english: "Nauru", native: "Nauru", english_language: "Nauruan, English", native_language: "Dorerin Naoero, English" },
    //     NP: { english: "Nepal", native: "नेपाल", english_language: "Nepali", native_language: "नेपाली" },
    NL: { english: "Netherlands", native: "Nederland", english_language: "Dutch", native_language: "Nederlands", language_code: "nl" },
    //     NZ: { english: "New Zealand", native: "New Zealand", english_language: "English, Māori", native_language: "English, Te Reo Māori" },
    //     NI: { english: "Nicaragua", native: "Nicaragua", english_language: "Spanish", native_language: "Español" },
    //     NE: { english: "Niger", native: "Niger", english_language: "French", native_language: "Français" },
    //     NG: { english: "Nigeria", native: "Nigeria", english_language: "English", native_language: "English" },
    //     NU: { english: "Niue", native: "Niue", english_language: "English, Niuean", native_language: "English, Vagahau Niue" },
    //     NF: { english: "Norfolk Island", native: "Norfolk Island", english_language: "English", native_language: "English" },
    //     MP: {
    //         english: "Northern Mariana Islands",
    //         native: "Northern Mariana Islands",
    //         english_language: "English, Chamorro",
    //         native_language: "English, Chamoru",
    //     },
    //     NO: { english: "Norway", native: "Norge", english_language: "Norwegian", native_language: "Norsk" },
    //     OM: { english: "Oman", native: "عمان", english_language: "Arabic", native_language: "العربية" },
    //     PK: { english: "Pakistan", native: "پاکستان", english_language: "Urdu, English", native_language: "اردو, English" },
    //     PW: { english: "Palau", native: "Palau", english_language: "English, Palauan", native_language: "English, Palauan" },
    //     PS: { english: "Palestinian Territory", native: "فلسطين", english_language: "Arabic", native_language: "العربية" },
    //     PA: { english: "Panama", native: "Panamá", english_language: "Spanish", native_language: "Español" },
    //     PG: {
    //         english: "Papua New Guinea",
    //         native: "Papua Niugini",
    //         english_language: "English, Tok Pisin, Hiri Motu",
    //         native_language: "English, Tok Pisin, Hiri Motu",
    //     },
    //     PY: { english: "Paraguay", native: "Paraguay", english_language: "Spanish, Guarani", native_language: "Español, Guarani" },
    //     PE: { english: "Peru", native: "Perú", english_language: "Spanish", native_language: "Español" },
    //     PH: { english: "Philippines", native: "Pilipinas", english_language: "Filipino, English", native_language: "Filipino, English" },
    //     PN: { english: "Pitcairn", native: "Pitcairn Islands", english_language: "English", native_language: "English" },
    PL: { english: "Poland", native: "Polska", english_language: "Polish", native_language: "Polski", language_code: "pl" },
    PT: { english: "Portugal", native: "Portugal", english_language: "Portuguese", native_language: "Português", language_code: "pt" },
    //     PR: { english: "Puerto Rico", native: "Puerto Rico", english_language: "Spanish, English", native_language: "Español, English" },
    //     QA: { english: "Qatar", native: "قطر", english_language: "Arabic", native_language: "العربية" },
    RO: { english: "Romania", native: "România", english_language: "Romanian", native_language: "Română", language_code: "ro" },
    //     RU: { english: "Russia", native: "Россия", english_language: "Russian", native_language: "Русский" },
    //     RW: {
    //         english: "Rwanda",
    //         native: "Rwanda",
    //         english_language: "Kinyarwanda, French, English",
    //         native_language: "Ikinyarwanda, Français, English",
    //     },
    //     KN: { english: "Saint Kitts and Nevis", native: "Saint Kitts and Nevis", english_language: "English", native_language: "English" },
    //     LC: { english: "Saint Lucia", native: "Saint Lucia", english_language: "English", native_language: "English" },
    //     WS: { english: "Samoa", native: "Samoa", english_language: "Samoan, English", native_language: "Gagana fa'a Samoa, English" },
    //     SM: { english: "San Marino", native: "San Marino", english_language: "Italian", native_language: "Italiano" },
    //     ST: { english: "Sao Tome and Principe", native: "São Tomé e Príncipe", english_language: "Portuguese", native_language: "Português" },
    //     SA: { english: "Saudi Arabia", native: "السعودية", english_language: "Arabic", native_language: "العربية" },
    //     SN: { english: "Senegal", native: "Sénégal", english_language: "French", native_language: "Français" },
    //     RS: { english: "Serbia", native: "Србија", english_language: "Serbian", native_language: "Српски" },
    //     SC: { english: "Seychelles", native: "Seychelles", english_language: "French, English", native_language: "Français, English" },
    //     SL: { english: "Sierra Leone", native: "Sierra Leone", english_language: "English", native_language: "English" },
    //     SG: {
    //         english: "Singapore",
    //         native: "Singapore",
    //         english_language: "English, Malay, Tamil, Chinese",
    //         native_language: "English, Bahasa Melayu, தமிழ், 中文",
    //     },
    //     SX: { english: "Sint Maarten", native: "Sint Maarten", english_language: "Dutch, English", native_language: "Nederlands, English" },
    SK: { english: "Slovakia", native: "Slovensko", english_language: "Slovak", native_language: "Slovenčina", language_code: "sk" },
    SI: { english: "Slovenia", native: "Slovenija", english_language: "Slovene", native_language: "Slovenščina", language_code: "sl" },
    //     SB: { english: "Solomon Islands", native: "Solomon Islands", english_language: "English", native_language: "English" },
    //     SO: { english: "Somalia", native: "Soomaaliya", english_language: "Somali", native_language: "Af Soomaali" },
    //     ZA: {
    //         english: "South Africa",
    //         native: "South Africa",
    //         english_language: "English, Afrikaans, more",
    //         native_language: "English, Afrikaans, meer",
    //     },
    //     SS: { english: "South Sudan", native: "South Sudan", english_language: "English", native_language: "English" },
    ES: { english: "Spain", native: "España", english_language: "Spanish", native_language: "Español", language_code: "es" },
    //     LK: { english: "Sri Lanka", native: "ශ්‍රී ලංකා / இலங்கை", english_language: "Sinhala, Tamil", native_language: "සිංහල, தமிழ்" },
    //     SD: { english: "Sudan", native: "السودان", english_language: "Arabic", native_language: "العربية" },
    //     SR: { english: "Suriname", native: "Suriname", english_language: "Dutch", native_language: "Nederlands" },
    //     SZ: { english: "Swaziland", native: "eSwatini", english_language: "English, Swati", native_language: "English, SiSwati" },
    SE: { english: "Sweden", native: "Sverige", english_language: "Swedish", native_language: "Svenska", language_code: "sv" },
    //     CH: {
    //         english: "Switzerland",
    //         native: "Schweiz/Suisse/Svizzera/Svizra",
    //         english_language: "German, French, Italian, Romansh",
    //         native_language: "Deutsch, Français, Italiano, Rumantsch",
    //     },
    //     SY: { english: "Syria", native: "سوريا", english_language: "Arabic", native_language: "العربية" },
    //     TW: { english: "Taiwan", native: "台灣", english_language: "Chinese", native_language: "中文" },
    //     TJ: { english: "Tajikistan", native: "Тоҷикистон", english_language: "Tajik", native_language: "Тоҷикӣ" },
    //     TZ: { english: "Tanzania", native: "Tanzania", english_language: "Swahili, English", native_language: "Kiswahili, English" },
    //     TH: { english: "Thailand", native: "ประเทศไทย", english_language: "Thai", native_language: "ไทย" },
    //     TG: { english: "Togo", native: "Togo", english_language: "French", native_language: "Français" },
    //     TK: { english: "Tokelau", native: "Tokelau", english_language: "Tokelauan, English", native_language: "Tokelau, English" },
    //     TO: { english: "Tonga", native: "Tonga", english_language: "Tongan, English", native_language: "Lea Faka-Tonga, English" },
    //     TT: { english: "Trinidad and Tobago", native: "Trinidad and Tobago", english_language: "English", native_language: "English" },
    //     TN: { english: "Tunisia", native: "تونس", english_language: "Arabic", native_language: "العربية" },
    //     TR: { english: "Turkey", native: "Türkiye", english_language: "Turkish", native_language: "Türkçe" },
    //     TM: { english: "Turkmenistan", native: "Türkmenistan", english_language: "Turkmen", native_language: "Türkmen" },
    //     TC: {
    //         english: "Turks and Caicos Islands",
    //         native: "Turks and Caicos Islands",
    //         english_language: "English",
    //         native_language: "English",
    //     },
    //     TV: { english: "Tuvalu", native: "Tuvalu", english_language: "Tuvaluan, English", native_language: "Tuvalu, English" },
    //     UG: { english: "Uganda", native: "Uganda", english_language: "English, Swahili", native_language: "English, Kiswahili" },
    //     UA: { english: "Ukraine", native: "Україна", english_language: "Ukrainian", native_language: "Українська" },
    //     AE: { english: "United Arab Emirates", native: "الإمارات العربية المتحدة", english_language: "Arabic", native_language: "العربية" },
    // GB: {
    //     english: "United Kingdom",
    //     native: "United Kingdom",
    //     english_language: "English",
    //     native_language: "English",
    //     language_code: "en",
    // },
    //     US: { english: "United States", native: "United States", english_language: "English", native_language: "English" },
    //     UY: { english: "Uruguay", native: "Uruguay", english_language: "Spanish", native_language: "Español" },
    //     UZ: { english: "Uzbekistan", native: "O‘zbekiston", english_language: "Uzbek", native_language: "O‘zbek" },
    //     VU: {
    //         english: "Vanuatu",
    //         native: "Vanuatu",
    //         english_language: "Bislama, English, French",
    //         native_language: "Bislama, English, Français",
    //     },
    //     VE: { english: "Venezuela, Bolivarian Republic of", native: "Venezuela", english_language: "Spanish", native_language: "Español" },
    //     VN: { english: "Viet Nam", native: "Việt Nam", english_language: "Vietnamese", native_language: "Tiếng Việt" },
    //     VI: { english: "Virgin Islands", native: "Virgin Islands", english_language: "English", native_language: "English" },
    //     YE: { english: "Yemen", native: "اليمن", english_language: "Arabic", native_language: "العربية" },
    //     ZM: { english: "Zambia", native: "Zambia", english_language: "English", native_language: "English" },
    //     ZW: {
    //         english: "Zimbabwe",
    //         native: "Zimbabwe",
    //         english_language: "English, Shona, Sindebele",
    //         native_language: "English, chiShona, isiNdebele",
    //     },
};

// const countryCodeDetails = {
//     GB: {
//         english: "United Kingdom",
//         native: "United Kingdom",
//         english_language: "English",
//         native_language: "English",
//         language_code: "en",
//     },
//     CN: { english: "China", native: "中国", english_language: "Chinese", native_language: "中文", language_code: "zh" },
// };

function getCountryLanagesDetails() {
    return fullCountryCodeDetails;
}

export { getCountryLanagesDetails };
