// https://mariestarck.com/how-to-create-a-bottom-tab-navigator/

import React, { useEffect, useState } from "react";
import { Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHome, faUserCircle, faCircleInfo, faLightbulb } from "@fortawesome/free-solid-svg-icons";

// Pages
const { getFormateFirstName, getLoginState, getLocalFirstName, setLoginState } = require("../../lib");

const initialLoggedInState = getLoginState();
const firstNameLS = getLocalFirstName();

function Navigation(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(initialLoggedInState);
    const [firstName, setFirstName] = useState(firstNameLS);
    const tabs = [
        {
            route: "",
            icon: faHome,
            label: "Home",
        },
        // {
        //     route: "/check",
        //     icon: faSearch,
        //     label: "Check",
        // },
        // {
        //     route: "/aboutUs",
        //     icon: faCircleInfo,
        //     label: "About Us",
        // },
        {
            route: "/ai-assistant",
            icon: faLightbulb,
            label: "AI Assistant",
        },
    ];
    if (!isLoggedIn) {
        tabs.push({
            route: "/signin",
            icon: faUserCircle,
            label: "Sign in/up",
        });
    } else {
        tabs.push({
            route: "/profile",
            icon: faUserCircle,
            label: `Hi ${getFormateFirstName(firstName)}`,
        });
    }

    useEffect(() => {
        setLoginState(isLoggedIn);
    }, [isLoggedIn]);

    return (
        <div className="navbar-container" style={{ background: "#9de3d6" }}>
            <nav
                className="navbar navbar-expand-md navbar-light d-none d-lg-block sticky-top"
                role="navigation"
                style={{ background: "#9de3d6", height: "100%", height: "60px" }}
            >
                <div className="container-fluid" style={{ margin: "auto" }}>
                    <a className="navbar-brand" href="/" style={{ width: "28%", color: "white", fontSize: "1.5em", fontWeight: "bold" }}>
                        GlasHive AI
                    </a>
                    <Nav className="ml-auto">
                        {tabs.map((tab, index) => (
                            <NavItem key={`tab-${index}`}>
                                <NavLink
                                    to={tab.route}
                                    className="nav-link"
                                    component={<span>tab.label</span>}
                                    style={{ color: "white", fontSize: "1.3em" }}
                                >
                                    <span>{tab.label}</span>
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
            </nav>
            <nav
                className="py-0 navbar fixed-bottom navbar-light d-block d-lg-none"
                role="navigation"
                style={{ background: "#9de3d6", height: "60px" }}
            >
                <Nav className="w-100">
                    <div
                        className=" d-flex flex-row w-100 vertical-center-child"
                        style={{
                            display: "flex",
                            // flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <NavItem key={`tab-${index}`} style={{ display: "flex", justifyContent: "center", width: "110px" }}>
                                <NavLink
                                    to={tab.route}
                                    className="nav-link bottom-nav-link"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        // justifyContent: "space-around",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <FontAwesomeIcon size="lg" icon={tab.icon} />
                                    <div
                                        className="bottom-tab-label"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ maxWidth: "110px" }}>{tab.label}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        ))}
                    </div>
                </Nav>
            </nav>
        </div>
    );
}

export default Navigation;
