import React from "react";

function Footer() {
    const todaysDate = new Date();
    const currentYear = todaysDate.getFullYear();
    return (
        <div
            style={{ background: "#9de3d6" }}
            className="footer navbar navbar-expand-md navbar-light d-none d-lg-block fixed-bottom"
            role="navigation"
        >
            <footer className="py-2 fixed-bottom vertical-center-parent" style={{ background: "#9de3d6", height: "60px" }}>
                <div className="container vertical-center-child" style={{ background: "#9de3d6", fontSize: "1.3em" }}>
                    <p className="m-0 text-center text-white">Your Best Choice for IT Services - Copyright &copy; GlasHive {currentYear}</p>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
