//---------------------------------------
const {
    nameRegex,
    emailRegex,
    passwordRegex,
    inviteCodeRegex,
    resetCodeRegex,
    actionFeedbacks,
    textConsumedCredits,
    imageConsumedCredits,
    defaultLanguageCode,
    defaultLanguage,
    imageMaxWidth,
    imageMaxHeight,
} = require("./config");
const { getLocalCredits, setLocalCredits, removeLocalItems, getLocalMessages, setLocalMessages, getLoginState } = require("./session");
const { postRequest } = require("./httpRequest");

import { readAndCompressImage } from "browser-image-resizer";

//---------------------------------------
function isEmptyNullUndefined(val) {
    return !!(val === undefined || val == null || val.length <= 0 || Object.keys(val).length === 0);
}

function isJsonEmpty(obj) {
    if (obj == null || obj === undefined) return true;
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

function hasIndex(indexStr) {
    if (indexStr.includes("[") && indexStr.includes("]")) return true;
    return false;
}

function extractMainKey(indexStr) {
    return indexStr.substring(0, indexStr.indexOf("["));
}

function extractIndex(indexStr) {
    const subStr = indexStr.substring(indexStr.indexOf("[") + 1);
    const subSubStr = subStr.substring(0, subStr.indexOf("]"));
    return subSubStr;
}

function jsonKeyExists(jsonObject, keyList, checkNullEmpty = false) {
    // e.g. jsonObject, 'key1.key2.key3'
    if (isJsonEmpty(jsonObject)) return false;
    try {
        const keyArr = keyList.split(".");
        let tempValue = jsonObject;
        for (let i = 0; i < keyArr.length; i += 1) {
            if (hasIndex(keyArr[i])) {
                const mainKey = extractMainKey(keyArr[i]);
                const index = extractIndex(keyArr[i]);
                tempValue = tempValue[mainKey][index];
            } else {
                tempValue = tempValue[keyArr[i]];
            }
        }
        if (checkNullEmpty) {
            return typeof tempValue != "undefined" && !isJsonEmpty(tempValue);
        }
        return typeof tempValue != "undefined";
    } catch (e) {
        return false;
    }
}

function datesDifferenceBySeconds(dateObj1, dateObj2) {
    return Math.abs(dateObj1.getTime() - dateObj2.getTime()) / 1000;
}

function getRandomInt(min = 0, max = 100) {
    const ceiledMin = Math.ceil(min);
    const ceiledMax = Math.floor(max);
    return Math.floor(Math.random() * (ceiledMax - ceiledMin + 1)) + ceiledMin;
}

function replaceSpaces(str, replace = "") {
    return str.replace(/ /g, replace);
}

function getYYYYMMDD(dateObject) {
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Adding 1 to month as it's zero-based
    const day = String(dateObject.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

function invalidInputValues(inputValues = {}) {
    let invalidInputs = [];
    const keys = Object.keys(inputValues);
    keys.forEach((key) => {
        if (isEmptyNullUndefined(inputValues[key])) invalidInputs.push(key);
    });
    return invalidInputs;
}

function unmatchValues(
    inputValues = [
        { a: 1, b: 1 },
        { c: 2, d: 2 },
    ]
) {
    let invalidInputs = [];
    inputValues.forEach((item) => {
        const tempKeys = Object.keys(item);
        if (item[tempKeys[0]] !== item[tempKeys[1]]) invalidInputs.push(`${tempKeys[0]} <--> ${tempKeys[1]}`);
    });
    return invalidInputs;
}

function invalidEmails(emails = { "email 1": "example@example.com", "email 2": "example@example.com" }) {
    let invalidInputs = [];
    const keys = Object.keys(emails);
    keys.forEach((key) => {
        if (!emailRegex.test(emails[key])) invalidInputs.push(key);
    });
    return invalidInputs;
}

function validatePassword(password) {
    return passwordRegex.test(password);
}

function validateName(name) {
    return nameRegex.test(name);
}

function validateEmail(email) {
    return emailRegex.test(email);
}

function validateResetCode(resetCode) {
    return resetCodeRegex.test(resetCode);
}

function validateInviteCode(inviteCode) {
    return inviteCodeRegex.test(inviteCode);
}

function setInputHint(warningText, defaultHint) {
    return isEmptyNullUndefined(warningText) ? defaultHint : <span style={{ color: "red" }}>{warningText}</span>;
}

function allEmpty(valueList = []) {
    for (let i = 0; i < valueList.length; i++) {
        if (!isEmptyNullUndefined(valueList[i].trim())) return false;
    }
    return true;
}

function allNotEmpty(valueList = []) {
    for (let i = 0; i < valueList.length; i++) {
        if (isEmptyNullUndefined(valueList[i].trim())) return false;
    }
    return true;
}

function delay(ms) {
    // 1 s = 1000 ms
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

function initialMessages() {
    let initMessages = [
        {
            initaltext: true,
            type: "separator",
            props: {
                content: getYYYYMMDD(new Date()),
            },
        },
        {
            initaltext: true,
            props: {
                model: {
                    role: "assistant",
                    messageType: "Text",
                    // message: "Hello, I am GlasHive your AI assistant. How can I assist you?",
                    message: `Hello, I am GlasHive - your AI assistant. \nPlease send me images and text to: \n- Chat and assist you\n- Recognise images, e.g.,viewpoints\n- Translate text from images, e.g., menus\n- Recommend viewpoints, food, etc.\n- And more...`,
                    sentTime: new Date().toLocaleDateString(),
                    sender: "GHAI",
                    direction: "incoming",
                    chat_session: true,
                },
            },
        },
    ];
    if (!getLoginState()) {
        initMessages.push({
            initaltext: true,
            props: {
                model: {
                    role: "assistant",
                    messageType: "Text",
                    message: "Please sign in to use all features.",
                    sentTime: new Date().toLocaleDateString(),
                    sender: "GHAI",
                    direction: "incoming",
                    chat_session: true,
                },
            },
        });
        initMessages.push({
            initaltext: true,
            props: {
                model: {
                    role: "assistant",
                    messageType: "Text",
                    message: "Great News!!! Please sign up to get 100 free credits.",
                    sentTime: new Date().toLocaleDateString(),
                    sender: "GHAI",
                    direction: "incoming",
                    chat_session: true,
                },
            },
        });
    } else {
        initMessages.push({
            initaltext: true,
            props: {
                model: {
                    role: "assistant",
                    messageType: "Text",
                    message: "What can I do for you?",
                    sentTime: new Date().toLocaleDateString(),
                    sender: "GHAI",
                    direction: "incoming",
                    chat_session: true,
                },
            },
        });
        const localSavedMessages = getLocalMessages();
        if (!isEmptyNullUndefined(localSavedMessages)) {
            initMessages = localSavedMessages;
        }
    }
    return initMessages;
}

function getFormateFirstName(firstName = "", maxChars = 6) {
    const trimmedFirstName = firstName.trim();
    if (trimmedFirstName.length > maxChars) {
        return trimmedFirstName.slice(0, maxChars) + "...";
    } else {
        return trimmedFirstName;
    }
}

function getInitalImageContent(image, index) {
    return {
        props: {
            model: {
                role: "user",
                messageType: "file",
                name: "Image content",
                message: "I sent you image(s) and you described the images below",
                type: "html",
                sourcetype: "image",
                sender: "user",
                direction: "outgoing",
                file_url: image.url,
                file_index: index,
                chat_session: true,
            },
        },
    };
}

function formateTextToMessage(text) {
    return {
        props: {
            model: {
                role: "user",
                messageType: "Text",
                message: text,
                sender: "user",
                direction: "outgoing",
                chat_session: true,
            },
        },
    };
}

function formateReturnTextToMessage(message = actionFeedbacks.chatServerError, fileType = "text", fileIndex = null) {
    return [
        {
            file_type: fileType,
            message,
            file_index: fileIndex,
        },
    ];
}

function updateLocalCredits(consumedCredits) {
    const localCredits = getLocalCredits();
    setLocalCredits(Number(localCredits) - Math.abs(consumedCredits));
}

function handleLocalSaveForSignOut() {
    const remoedItems = ["is_logged_in", "id", "email", "first_name", "last_name", "token", "token_expire_date", "credits", "messages"];
    removeLocalItems(remoedItems);
}

function getLastNMessages(messages, lastN = 5, keepedMessageIndices = []) {
    if (messages.length > lastN) {
        const keepedMessages = [];
        keepedMessageIndices.forEach((keepedIndex) => {
            keepedMessages.push(messages[keepedIndex]);
        });
        const lastNMessages = messages.slice(messages.length - lastN - 1);
        return [...keepedMessages, ...lastNMessages];
    } else {
        return messages;
    }
}

function modifyImgTags(htmlString, width = "80px", height = "80px", supportNImages = 2) {
    let imgCount = 0; // Initialize a counter for the number of <img> tags processed

    // Define a regular expression to match <img> tags with base64 src attributes
    const imgTagRegex = /<img\s+[^>]*>/gi;

    // Use replace method with a callback function to modify matched <img> tags
    const modifiedHtmlString = htmlString.replace(imgTagRegex, (imgTag) => {
        if (imgCount < supportNImages) {
            // Increment the count of processed <img> tags
            imgCount++;

            // Check if the <img> tag contains an 'alt' attribute
            const hasAltAttribute = imgTag.includes('alt="');

            // Append or update the 'width' and 'height' attributes in the <img> tag
            let modifiedTag;
            if (hasAltAttribute) {
                // Update the 'width' and 'height' attributes if 'alt' attribute is present
                modifiedTag = imgTag.replace(/(alt="[^"]*")/, `$1 width="${width}" height="${height}"`);
            } else {
                // Append 'width' and 'height' attributes if 'alt' attribute is not present
                modifiedTag = imgTag.replace(/\/?>$/, ` width="${width}" height="${height}" />`);
            }

            return modifiedTag;
        } else {
            // If more than 'supportNImages' <img> tags are encountered, remove them
            return "";
        }
    });

    return modifiedHtmlString;
}

function base64toFile(base64Data, filename = `${Date.now()}.png`, mimeType = "image/png") {
    // Split the base64 string to get the data part
    const base64Content = base64Data.split(";base64,").pop();

    // Convert base64 to binary content
    const byteCharacters = atob(base64Content);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    // Create a Blob object from binary content
    const blob = new Blob(byteArrays, { type: mimeType });
    // Create a File object from Blob
    const file = new File([blob], filename, { type: mimeType });
    return file;
}

function extractSrcValues(inputString) {
    const srcRegex = /<img[^>]+src="([^"]+)"/gi; // Regular expression to match src attributes

    const srcValues = []; // Array to store extracted src attribute values

    // Using a loop to iterate over all matches found by the regular expression
    let match;
    while ((match = srcRegex.exec(inputString)) !== null) {
        const srcValue = match[1]; // Get the value captured in the first capturing group
        srcValues.push(srcValue); // Push the src value into the array
    }

    return srcValues;
}

function getFinalImageQuestion(inputValue, radioValue) {
    if ((radioValue === "other" || radioValue === "") && isEmptyNullUndefined(inputValue.trim())) return inputValue.trim();
    return "";
}

function blobToFile(blob, fileName, mimeType) {
    // Simulate the File constructor
    return new File([blob], fileName, { type: mimeType });
}

/**
 * Resize an image file while maintaining aspect ratio and ensuring that
 * neither width nor height exceeds the max pixels.
 * @param {File} inputFile - Input image file.
 * @returns {Promise<File>} - Resized image file.
 */
async function resizeImage(inputFile) {
    try {
        // Create an image element to get the dimensions
        const image = new Image();
        const imageUrl = URL.createObjectURL(inputFile);
        return new Promise((resolve, reject) => {
            image.onload = async () => {
                const { width, height } = image;
                URL.revokeObjectURL(imageUrl);
                // Check if resizing is necessary
                if (width <= imageMaxWidth && height <= imageMaxHeight) {
                    resolve(inputFile); // Return original file if no resize needed
                } else {
                    // Resize the image if dimensions exceed the max pixels
                    try {
                        const resizeConfig = {
                            quality: 1,
                            maxWidth: imageMaxWidth,
                            maxHeight: imageMaxHeight,
                            autoRotate: true,
                            debug: true,
                        };
                        const resizedImage = await readAndCompressImage(inputFile, resizeConfig);
                        const resizedFile = blobToFile(resizedImage, inputFile.name, inputFile.type);
                        resolve(resizedFile);
                    } catch (resizeError) {
                        reject(`Error resizing image: ${resizeError.message}`);
                    }
                }
            };
            image.onerror = () => {
                reject("Error loading image for dimensions check.");
            };
            image.src = imageUrl;
        });
    } catch (error) {
        console.error("Error in resizeImage:", error);
        return inputFile;
    }
}

async function getDataForHomePage(
    messages,
    images = [],
    hasImage = false,
    imageExtraInfo = "",
    languageCode = defaultLanguageCode,
    language = defaultLanguage,
    radioValue = ""
) {
    let res = {};
    try {
        if (hasImage) {
            //------------------------Old Version 1 do not delete
            // let base64StrList = [];
            // for (let i = 0; i < images.length; i++) {
            //     base64StrList.push(await imageToBase64Str(images[i].file));
            // }
            // // console.log("imageQuestion: ", imageQuestion);
            // const gptReplies = await imageToText(base64StrList, imageQuestion);
            // aiReplies.push({
            //     file_type: "image",
            //     message: gptReplies.message.content,
            // });
            // // Update users credits
            // const userEmail = getLocalEmail();
            // return aiReplies;
            //------------------------Old Version 1 do not delete

            //------------------------Version 2
            const formData = new FormData();
            formData.append(`image_extra_info`, imageExtraInfo);
            formData.append(`radio_value`, radioValue);
            formData.append(`prefer_language_code`, languageCode);
            formData.append(`prefer_language`, language);
            for (let i = 0; i < images.length; i++) {
                const resizedImage = await resizeImage(images[i].file);
                formData.append(`images`, resizedImage);
            }
            const res = await postRequest("/home-page-images", formData, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                // onUploadProgress: (progressEvent) => {
                //     const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                //     setUploadProgress(progress);
                // },
            });
            if (res.data.code === 200) {
                const data = res.data.data;
                updateLocalCredits(imageConsumedCredits);
                return formateReturnTextToMessage(data.chat_reply);
            } else {
                return formateReturnTextToMessage(actionFeedbacks.chatServerError);
            }
        } else {
            const chatMessages = getChatMessages(messages);
            const lastNMessages = getLastNMessages(chatMessages);
            res = await postRequest("/home-page-data", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    messages: lastNMessages,
                    prefer_language_code: languageCode,
                    prefer_language: language,
                }),
            });
            if (res.data.code === 200) {
                const data = res.data.data;
                updateLocalCredits(textConsumedCredits);
                return formateReturnTextToMessage(data.chat_reply);
            } else {
                return formateReturnTextToMessage(actionFeedbacks.chatServerError);
            }
        }
    } catch (error) {
        console.log(error);
        return formateReturnTextToMessage(actionFeedbacks.chatServerError);
    }
}

function getChatMessages(resMessages) {
    let chatSessionMessages = [];
    for (let i = 0; i < resMessages.length; i++) {
        if (jsonKeyExists(resMessages[i], "props.model.chat_session") && resMessages[i].props.model.chat_session) {
            chatSessionMessages.push({
                role: resMessages[i].props.model.role,
                content: resMessages[i].props.model.message,
                is_chat: true,
            });
        }
    }
    return chatSessionMessages;
}

function extractTextInputStr(inputString) {
    // Define a regular expression to match <img> tags and their attributes
    const imgTagRegex = /<img[^>]+>/gi;

    // Use replace method with an empty string to remove <img> tags and attributes
    const textOnly = inputString.replace(imgTagRegex, " ");

    // Trim and replace consecutive spaces with a single space
    const cleanedText = textOnly.trim();

    return cleanedText;
}

function removeSpacesAndHtmlSpaces(inputString) {
    // Remove regular spaces, tabs, newlines, and carriage returns
    const withoutSpaces = inputString.replace(/\s/g, "");

    // Remove HTML spaces such as &nbsp;
    const withoutHtmlSpaces = withoutSpaces.replace(/&nbsp;|&#160;/gi, "");

    return withoutHtmlSpaces;
}

export {
    isEmptyNullUndefined,
    jsonKeyExists,
    isJsonEmpty,
    datesDifferenceBySeconds,
    getRandomInt,
    replaceSpaces,
    getYYYYMMDD,
    invalidInputValues,
    unmatchValues,
    invalidEmails,
    validatePassword,
    validateName,
    validateEmail,
    validateInviteCode,
    setInputHint,
    allEmpty,
    allNotEmpty,
    validateResetCode,
    delay,
    initialMessages,
    getFormateFirstName,
    getInitalImageContent,
    formateReturnTextToMessage,
    updateLocalCredits,
    handleLocalSaveForSignOut,
    getLastNMessages,
    modifyImgTags,
    base64toFile,
    extractSrcValues,
    formateTextToMessage,
    getFinalImageQuestion,
    getDataForHomePage,
    extractTextInputStr,
    removeSpacesAndHtmlSpaces,
};
