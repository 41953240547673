/**
 * Tools and functions to support other files
 * @author Glashive
 * @date 13/03/2024
 * @version 2
 * @references
 * https://platform.openai.com/docs/guides/vision?lang=python
 */

// --------------------------------------------------
const OpenAI = require("openai");

const openai = new OpenAI({ apiKey: process.env.REACT_APP_GPT_API_KEY, dangerouslyAllowBrowser: true });

// --------------------------------------------------
function getAIReplyHeader(index, gptRepliesLen = 1, type = "image") {
    if (gptRepliesLen <= 1) return "";
    switch (index) {
        case 0:
            return `The first ${type}: `;
        case 1:
            return `The second ${type}: `;
        case 2:
            return `The third ${type}: `;
        // Add more cases as needed
        default:
            return `The ${index + 1}th ${type}: `;
    }
}

module.exports = {
    openai: openai,
    getAIReplyHeader,
};
