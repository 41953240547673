/**
 * Tools and functions to support other files
 * @author Glashive
 * @date 13/03/2024
 * @version 2
 * @references
 * https://platform.openai.com/docs/guides/vision?lang=python
 */

// --------------------------------------------------

const { openai } = require("./utils");

const defaultImageQuestion = "What are in the uploaded image(s)? And introduce it with 100 words.";
module.exports = {
    openai: openai,
    // For image to text
    imageToTextMaxToken: 500,
    visionModel: "gpt-4-vision-preview",
    introduceImageQuestion: defaultImageQuestion,
    translationImageQuestion:
        "Please identify the text in the image(s), and output the text with the format 'orignial text (translated text)' if the orginial text is not English, like, 中文 (Chinese).", // Soupe de Jour (Soup of the day)
    otherImageQuestion: defaultImageQuestion,
    // For translation
    translationModel: "gpt-3.5-turbo-16k",
    defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
    translateSysContent: "You will be provided text, and your task is to translate all English text to",
    // For chatting
    chatModel: "gpt-3.5-turbo-16k",

    translationTextQuestion:
        "You will be provided text, and your task is to translate all text into requested language, and output the text with the format 'orignial text (translated text)' if the orginial text is not English, like, 中文 (Chinese).  The translated language is ",
};
