import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Row, Col } from "reactstrap";
import { getRequest, isEmptyNullUndefined, jsonKeyExists } from "../../lib";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt, faGlobe, faCameraRotate, faLightbulb } from "@fortawesome/free-solid-svg-icons";

import moherImage from "../../images/moher.png";
import frenchMenuImage from "../../images/french-menu.jpg";

import news1 from "../../images/phone-news/news_1.png";
import news2 from "../../images/phone-news/news_2.png";
import news3 from "../../images/phone-news/news_3.png";
import news4 from "../../images/phone-news/news_4.png";

// https://www.youtube.com/watch?v=OtqxDT0IlHI.
// https://github.com/emetdas/Youtube-code/blob/master/web%20components/React-Swiper-Slider-3D-Coverflow/final/src/App.jsx
// https://www.youtube.com/watch?v=6O0Eb3drgpc
// https://github.com/devamitjha/swiper-slider/blob/main/src/App.js

import "./index.css";

//---------------------------------------
const intervalSecond = 6000;

function News(props) {
    const [index, setIndex] = useState(0);

    useEffect(() => {}, []);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const getCarousel = (
        <Row
            className="text-center justify-content-center"
            name="wigets"
            style={{ maxHeight: "350px", marginLeft: "0px", marginRight: "0px", padding: "0px" }}
        >
            <Col
                lg={10}
                md={12}
                xs={12}
                className="text-center align-self-center"
                style={{ maxHeight: "350px", marginLeft: "0px", marginRight: "0px", padding: "0px" }}
            >
                {/* Computer carousel news */}
                <Carousel className="computer-carousel-news" controls={false} activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item interval={intervalSecond} key={1} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>
                                    Viewpoints Recognition
                                </p>
                                <div
                                    className="col-xs-12 col-sm-6 col-md-6"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={moherImage} style={{ width: "300px", height: "200px" }} />
                                </div>
                                {/* <div className="col-xs-12 col-sm-2 col-md-2 d-flex align-items-center justify-content-center">
                                    <img src={arrowImage} style={{ width: "120px", height: "50px" }} /> 
                                    <FontAwesomeIcon
                                        icon={faLightbulb}
                                        className="icon"
                                        style={{ fontSize: "1.8em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}
                                    />
                                </div> */}
                                <div className="col-xs-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div style={{ textAlign: "justify", fontSize: "1.2em", width: "100%" }}>
                                        The image is a breath-taking view of the iconic{" "}
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=Cliffs of Moher">
                                            Cliffs of Moher
                                        </a>
                                        , located on the west coast of{" "}
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=Ireland">
                                            Ireland
                                        </a>
                                        . These majestic cliffs rise sharply from the{" "}
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=Atlantic Ocean">
                                            Atlantic Ocean
                                        </a>
                                        , reaching heights of up to 214 meters...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption>
                            {/* <a
                                style={{
                                    textDecoration: "none",
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontSize: "0.8em",
                                }}
                                // href={newsItem.news_link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span style={{ color: "#9de3d6", fontSize: "1.8em" }}>Chat</span>
                            </a> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={2} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Recommendation</p>
                                <div
                                    className="col-xs-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center"
                                    style={{ fontSize: "1.3em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        Please recommend 5 <br />
                                        famous viewpoints in Spain
                                    </div>
                                </div>
                                {/* <div className="col-xs-12 col-sm-2 col-md-2 d-flex align-items-center justify-content-center">
                                   <img src={arrowImage} style={{ width: "120px", height: "50px" }} /> 
                                    <FontAwesomeIcon
                                        icon={faCameraRotate}
                                        className="icon"
                                        style={{ fontSize: "1.8em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}
                                    />
                                </div> */}
                                <div className="col-xs-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div style={{ textAlign: "justify", fontSize: "1.2em" }}>
                                        <ol>
                                            <li key={1}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Park Güell"
                                                >
                                                    Park Güell
                                                </a>
                                                ,{" "}
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Barcelona"
                                                >
                                                    Barcelona
                                                </a>
                                                : This iconic park...
                                            </li>
                                            <li key={2}>
                                                <a className="link-success" target="_blank" href="https://www.google.com/search?q=Alhambra">
                                                    Alhambra
                                                </a>
                                                ,{" "}
                                                <a className="link-success" target="_blank" href="https://www.google.com/search?q=Granada">
                                                    Granada
                                                </a>
                                                : The Alhambra is...
                                            </li>
                                            <li key={3}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Montserrat"
                                                >
                                                    Montserrat
                                                </a>
                                                , Barcelona : This mountain...
                                            </li>
                                            <li key={4}>
                                                <a className="link-success" target="_blank" href="https://www.google.com/search?q=Tibidabo">
                                                    Tibidabo
                                                </a>
                                                , Barcelona: Located on...
                                            </li>
                                            <li key={5}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=El Tajo Gorge"
                                                >
                                                    El Tajo Gorge
                                                </a>
                                                ,{" "}
                                                <a className="link-success" target="_blank" href="https://www.google.com/search?q=Ronda">
                                                    Ronda
                                                </a>
                                                : Ronda is known...
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption>
                            {/* <a
                                style={{
                                    textDecoration: "none",
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontSize: "0.8em",
                                }}
                                // href={newsItem.news_link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span style={{ color: "#9de3d6", fontSize: "1.8em" }}>Chat</span>
                            </a> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={3} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Menu Translation</p>
                                <div
                                    className="col-xs-12 col-sm-6 col-md-6"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={frenchMenuImage} style={{ width: "160px", height: "200px" }} />
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div style={{ textAlign: "justify", fontSize: "1.2em", width: "100%" }}>
                                        <a
                                            className="link-success"
                                            target="_blank"
                                            href="https://www.google.com/search?q=Eiffel Café &amp; Bakery"
                                        >
                                            Eiffel Café &amp; Bakery
                                        </a>
                                        <br />
                                        <a
                                            className="link-success"
                                            target="_blank"
                                            href="https://www.google.com/search?q=Assiette de Fruits de Fondue de Brie"
                                        >
                                            Assiette de Fruits...
                                        </a>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7
                                        <br />
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=Baked brie">
                                            Baked brie
                                        </a>{" "}
                                        with{" "}
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=fruit compote">
                                            fruit compote
                                        </a>{" "}
                                        and...
                                        <br />
                                        <br />
                                        <a
                                            className="link-success"
                                            target="_blank"
                                            href="https://www.google.com/search?q=Pancakes au Gratin"
                                        >
                                            Pancakes au Gratin
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9
                                        <br />
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=Potato pancakes">
                                            Potato pancakes
                                        </a>{" "}
                                        with{" "}
                                        <a className="link-success" target="_blank" href="https://www.google.com/search?q=sour cream">
                                            sour cream
                                        </a>
                                        ...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={4} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Menu Suggestion</p>
                                <div
                                    className="col-xs-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center"
                                    style={{ fontSize: "1.3em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        I have a budget of 10 Euros for my meal.
                                        <br />
                                        Do you have any suggestions?
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div style={{ textAlign: "justify", fontSize: "1.2em" }}>
                                        Certainly! With a budget of 10 Euros, here are some meal suggestions for you:
                                        <ol>
                                            <li key={0}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Pancakes au Gratin"
                                                >
                                                    Pancakes au Gratin
                                                </a>{" "}
                                                (9 Euros) -{" "}
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Potato pancakes"
                                                >
                                                    Potato pancakes
                                                </a>{" "}
                                                with...
                                            </li>
                                            <li key={1}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Quiche Lorraine"
                                                >
                                                    Quiche Lorraine
                                                </a>{" "}
                                                (8 Euros) - A classic{" "}
                                                <a className="link-success" target="_blank" href="https://www.google.com/search?q=quiche">
                                                    quiche
                                                </a>
                                                ...
                                            </li>
                                            <li key={2}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Soupe du Jour"
                                                >
                                                    Soupe du Jour
                                                </a>{" "}
                                                (5 Euros) - Chef's daily{" "}
                                                <a className="link-success" target="_blank" href="https://www.google.com/search?q=soup">
                                                    soup
                                                </a>
                                                ...
                                            </li>
                                            <li key={3}>
                                                <a
                                                    className="link-success"
                                                    target="_blank"
                                                    href="https://www.google.com/search?q=Salade de Composée"
                                                >
                                                    Salade de Composée
                                                </a>{" "}
                                                (8 Euros) - Mixed greens with...
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                {/* Smart phone carousel news */}
                <Carousel className="phone-carousel-news" controls={false} activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item interval={intervalSecond} key={1} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Image Recognition</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news1} style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={2} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Recommendation</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news2} style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={3} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Menu Translation</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news3} style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={4} style={{ minHeight: "300px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "300px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Menu Suggestion</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news4} style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    );

    return <div>{getCarousel}</div>;
}

export default News;
